import React, { useState, useEffect, useCallback, useRef } from 'react';
import { format } from 'date-fns';
import 'jspdf-autotable';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext'; // Ensure this path is correct
import {
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Tooltip,
  Container,
  CircularProgress,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Card,
  CardContent,
  CardActions,
  Grid,
  Box,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  Checkbox,
  Collapse
} from '@mui/material';
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Menu as MenuIcon,
  Add as AddIcon
} from '@mui/icons-material';
import { collection, getDocs, query, where, doc, getDoc, setDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebaseConfig';

const MobilePayments = () => {
  const pressTimer = useRef(null);
  const [residents, setResidents] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [selectedResident, setSelectedResident] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState('300'); // Default payment amount
  const [checkNumber, setCheckNumber] = useState('');
  const [period, setPeriod] = useState({ start: new Date(), end: new Date() });
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [expandedCard, setExpandedCard] = useState(null);
  const [creatingPaymentDocs, setCreatingPaymentDocs] = useState(false); // New state for button loading

  const initializePeriod = useCallback((year, periodHalf) => {
    const start = new Date(year, periodHalf === 1 ? 0 : 6, 1);
    const end = new Date(year, periodHalf === 1 ? 5 : 11, 30);
    setPeriod({ start, end });
    fetchResidentsAndPayments(start, end, true);
  }, []);

  useEffect(() => {
    const today = new Date();
    initializePeriod(today.getFullYear(), today.getMonth() < 6 ? 1 : 2);
  }, [initializePeriod]);

  const fetchResidentsAndPayments = async (start, end, reset = false) => {
    setLoading(true);
    const paymentsQuery = query(
      collection(db, 'payments'),
      where('periodStart', '>=', start),
      where('periodEnd', '<=', end)
    );
    const paymentsSnapshot = await getDocs(paymentsQuery);
    const residentsData = await Promise.all(
      paymentsSnapshot.docs.map(async (paymentDoc) => {
        const residentRef = doc(db, 'residents', paymentDoc.data().residentId);
        const residentSnap = await getDoc(residentRef);
        if (residentSnap.exists()) {
          return {
            id: residentSnap.id,
            ...residentSnap.data(),
            lotNumber: Number(residentSnap.data().lotNumber),
            paymentId: paymentDoc.id,
            isPaid: paymentDoc.data().isPaid,
            paidDate: paymentDoc.data().paidDate?.toDate()?.toLocaleDateString(),
            paymentAmount: paymentDoc.data().paymentAmount,
            checkNumber: paymentDoc.data().checkNumber
          };
        } else {
          console.error('Payment document found without a corresponding resident:', paymentDoc.id);
          return null;
        }
      })
    );
    const sortedResidents = residentsData.filter(resident => resident !== null).sort((a, b) => a.lotNumber - b.lotNumber);
    setResidents(sortedResidents);
    setLoading(false);
  };

  const handlePaymentStatusChange = async (resident) => {
    const paymentRef = doc(db, 'payments', resident.paymentId || `new-payment-${resident.id}`);
    if (resident.isPaid) {
      const updateData = {
        isPaid: false,
        paidDate: null,
        paymentAmount: '',
        checkNumber: ''
      };
      await setDoc(paymentRef, updateData, { merge: true });
    } else {
      setSelectedResident(resident);
      setPaymentAmount(resident.paymentAmount || '300');
      setOpenPaymentDialog(true);
    }
    fetchResidentsAndPayments(period.start, period.end);
  };

  const handlePrevNextPeriod = (direction) => {
    const currentYear = period.start.getFullYear();
    const newYear = direction === 'next' ? (period.start.getMonth() === 0 ? currentYear : currentYear + 1) : (period.start.getMonth() === 0 ? currentYear - 1 : currentYear);
    const periodHalf = period.start.getMonth() === 0 ? 2 : 1;
    initializePeriod(newYear, periodHalf);
  };

  const clearPaymentsForPeriod = async () => {
    const paymentsQuery = query(collection(db, 'payments'), where('periodStart', '==', period.start), where('periodEnd', '==', period.end));
    const paymentsSnapshot = await getDocs(paymentsQuery);
    const deletePromises = paymentsSnapshot.docs.map(doc => deleteDoc(doc.ref));
    await Promise.all(deletePromises);
    showSnackbar("All payments for the period have been cleared.");
    setOpenDialog(false);
    fetchResidentsAndPayments(period.start, period.end);
  };

  const handlePaymentDialogSubmit = async () => {
    const paymentRef = doc(db, 'payments', selectedResident.paymentId || `new-payment-${selectedResident.id}`);
    const updateData = {
      isPaid: true,
      paidDate: serverTimestamp(),
      paymentAmount: paymentAmount,
      checkNumber: checkNumber
    };
    await setDoc(paymentRef, updateData, { merge: true });
    handlePaymentDialogClose();
    fetchResidentsAndPayments(period.start, period.end);
    setPaymentAmount('300');
    setCheckNumber('');
  };

  const handleInfoClick = (resident) => {
    setExpandedCard(expandedCard === resident.id ? null : resident.id);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const handleTouchStart = () => {
    pressTimer.current = window.setTimeout(() => {
      setOpenDialog(true);
    }, 3000); // Set to 3 seconds
  };

  const handleTouchEnd = () => {
    clearTimeout(pressTimer.current);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handlePaymentDialogClose = () => {
    setOpenPaymentDialog(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCreatePaymentDoc = async () => {
    setCreatingPaymentDocs(true);
    try {
      // Create missing payments for the period
      const start = period.start;
      const end = period.end;

      const residentsCollection = collection(db, 'residents');
      const residentsSnapshot = await getDocs(residentsCollection);
      let createdCount = 0;

      for (const residentDoc of residentsSnapshot.docs) {
        const paymentId = `${residentDoc.id}-${format(start, 'yyyy-MM')}`;
        const paymentRef = doc(db, 'payments', paymentId);
        const paymentSnap = await getDoc(paymentRef);

        if (!paymentSnap.exists()) {
          await setDoc(paymentRef, {
            residentId: residentDoc.id,
            isPaid: false,
            paidDate: null,
            periodStart: start,
            periodEnd: end,
            createdAutomatically: true,
            paymentAmount: '300', // Default payment amount
            checkNumber: ''
          });
          createdCount++;
        }
      }

      if (createdCount > 0) {
        showSnackbar(`${createdCount} payment document(s) created for new period.`);
      } else {
        showSnackbar("No new payment documents needed.");
      }
    } catch (error) {
      console.error("Failed to create payment documents:", error);
      showSnackbar("Failed to create payment documents.");
    }
    setCreatingPaymentDocs(false);
    fetchResidentsAndPayments(period.start, period.end);
  };

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  return (
    <Container>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuOpen}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            myHOA
          </Typography>
          <Button
            color="inherit"
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onClick={handleCreatePaymentDoc}
            disabled={creatingPaymentDocs}
            startIcon={creatingPaymentDocs ? <CircularProgress size={24} sx={{ color: 'white' }} /> : <AddIcon />}
          >
            {creatingPaymentDocs ? "" : "Create Payment Docs"}
          </Button>
        </Toolbar>
      </AppBar>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={() => navigate('/Profiles')}>Directory</MenuItem>
        <MenuItem onClick={() => navigate('/MobileLedger')}>Ledger</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
      <Box mt={10}>
      <Typography variant="h4" component="h1" gutterBottom mt={4}>
                    HOA Dues
                </Typography>
        <Box my={2} display="flex" justifyContent="space-between" alignItems="center">
          <IconButton onClick={() => handlePrevNextPeriod('prev')}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6">{`${format(period.start, 'MMM yyyy')} - ${format(period.end, 'MMM yyyy')}`}</Typography>
          <IconButton onClick={() => handlePrevNextPeriod('next')}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
        <Grid container spacing={2}>
          {loading ? (
            <CircularProgress />
          ) : (
            residents.map((resident) => (
              <Grid item xs={12} key={resident.id}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">{resident.firstName} {resident.lastName}</Typography>
                    <Typography color="textSecondary">Lot Number: {resident.lotNumber}</Typography>
                    <Typography color="textSecondary">Paid Date: {resident.paidDate || "Not Paid"}</Typography>
                  </CardContent>
                  <CardActions>
                    <Checkbox
                      checked={resident.isPaid}
                      onChange={() => handlePaymentStatusChange(resident)}
                      color="primary"
                    />
                    <Tooltip title="Info">
                      <IconButton onClick={() => handleInfoClick(resident)}>
                        {expandedCard === resident.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    </Tooltip>
                  </CardActions>
                  <Collapse in={expandedCard === resident.id} timeout="auto" unmountOnExit>
                    <CardContent>
                      <Typography>Payment Amount: ${resident.paymentAmount || "N/A"}</Typography>
                      <Typography>Check Number: {resident.checkNumber || "N/A"}</Typography>
                    </CardContent>
                  </Collapse>
                </Card>
              </Grid>
            ))
          )}
        </Grid>
      </Box>
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Clear Payment Documents</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to clear all payment documents for this period?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={clearPaymentsForPeriod} color="primary">Clear</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="info" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog open={openPaymentDialog} onClose={handlePaymentDialogClose}>
        <DialogTitle>Payment Information</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Payment Amount"
            type="number"
            fullWidth
            variant="standard"
            value={paymentAmount}
            onChange={(e) => setPaymentAmount(e.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
          <TextField
            margin="dense"
            label="Check Number"
            type="text"
            fullWidth
            variant="standard"
            value={checkNumber}
            onChange={(e) => setCheckNumber(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePaymentDialogClose}>Cancel</Button>
          <Button onClick={handlePaymentDialogSubmit}>Save</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default MobilePayments;
