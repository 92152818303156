import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Typography, Container, Paper, Grid, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DescriptionIcon from '@mui/icons-material/Description';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { useTheme } from '@mui/material/styles';
import emailjs from 'emailjs-com';

function Home() {
    const theme = useTheme();
    const [openAbout, setOpenAbout] = useState(false);
    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedback, setFeedback] = useState('');

    const handleOpenAbout = () => {
        setOpenAbout(true);
    };

    const handleCloseAbout = () => {
        setOpenAbout(false);
    };

    const handleOpenFeedback = () => {
        setOpenFeedback(true);
    };

    const handleCloseFeedback = () => {
        setOpenFeedback(false);
    };

    const handleSubmitFeedback = () => {
        const templateParams = {
            feedback: feedback 
        };

        emailjs.send('service_oy4wfrw', 'template_e29jwot', templateParams, '3RFTYojQthQm2u5yB')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                alert('Feedback sent successfully!');
                setFeedback('');
                handleCloseFeedback();
            }, (error) => {
                console.log('FAILED...', error);
                alert('Failed to send feedback: ' + error.text);
            });
    };

    const openTermsOfService = () => {
        window.open('/terms.pdf', '_blank');
    };

    return (
        <Container maxWidth="lg" sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '16px' }}>
            <Button
                variant="contained"
                color="primary"
                startIcon={<FeedbackIcon />}
                sx={{ position: 'absolute', top: 16, right: 16 }}
                onClick={handleOpenFeedback}
            >
                Feedback
            </Button>
            <Typography 
    variant="h3" 
    component="h1" 
    gutterBottom 
    sx={{ 
        color: theme.palette.primary.main, 
        textAlign: 'center', 
    }}
>
    myHOA Management Portal
</Typography>
            <Typography variant="h5" component="h2" gutterBottom sx={{ color: `${theme.palette.primary.main}7c`, textAlign: 'center' }}>
                Licensed to Tuscany Manor: V1.0P
            </Typography>
            <Grid container spacing={3} sx={{ my: 4 }} justifyContent="center">
                <Grid item xs={12} sm={6} md={4}>
                    <Paper elevation={3} sx={{ p: 2, textAlign: 'center', height: '100%', backgroundColor: theme.palette.background.paper }}>
                        <IconButton color="primary" onClick={handleOpenAbout} sx={{ fontSize: 40 }}>
                            <InfoIcon fontSize="inherit" />
                        </IconButton>
                        <Typography variant="h6" component="h3">
                            About
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                            Descriptions for each tab of the site.
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Paper elevation={3} sx={{ p: 2, textAlign: 'center', height: '100%', backgroundColor: theme.palette.background.paper }}>
                        <IconButton color="primary" onClick={openTermsOfService} sx={{ fontSize: 40 }}>
                            <DescriptionIcon fontSize="inherit" />
                        </IconButton>
                        <Typography variant="h6" component="h3">
                            Terms of Service
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                            Read the terms of service.
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>

            <Dialog open={openAbout} onClose={handleCloseAbout}>
                <DialogTitle sx={{ color: theme.palette.primary.contrastText, backgroundColor: theme.palette.secondary.main }}>About</DialogTitle>
                <DialogContent sx={{ backgroundColor: theme.palette.background.window }}>
                    <DialogContentText sx={{ color: theme.palette.primary.contrastText, '& ul': { pl: 2 }, '& li': { mb: 3 } }}>
                        <ul>
                            <li>
                                <strong style={{ color: theme.palette.primary.contrastText }}>Residents:</strong> This page serves as the resident account manager. 
                                Create, edit, and delete resident profiles from here.
                            </li>
                            <li>
                                <strong style={{ color: theme.palette.primary.contrastText }}>HOA Invoices:</strong> This page will generate PDF invoices using the resident 
                                profile information stored in the database. Resident attributes are stored as variables and are automatically inserted 
                                into the documents. Additionally, the invoices can be batch sent to the selected resident email addresses.
                            </li>
                            <li>
                                <strong style={{ color: theme.palette.primary.contrastText }}>HOA Dues:</strong> Manage HOA Dues payments records. All records are sorted by period 
                                and are stored in a backend database. Hold down on the create button for 3 seconds to clear all records for the period. Payments are stored as payment documents.
                            </li>
                            <li>
                                <strong style={{ color: theme.palette.primary.contrastText }}>Bills Payable:</strong> Manage all neighborhood billing records. All records are sorted 
                                and are stored in a backend database. Bills are stored as bill documents. 
                            </li>
                            <li>
                                <strong style={{ color: theme.palette.primary.contrastText }}>Ledger:</strong> Account dashbaord. Shows neighborhood balance at the top and includes options
                                to sort and filter the table. 
                            </li>
                        </ul>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.primary.contrastText }}>
                    <Button onClick={handleCloseAbout} color="primary" sx={{ color: theme.palette.primary.contrastText }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openFeedback} onClose={handleCloseFeedback}>
                <DialogTitle>Submit Feedback</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter your feedback below:
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Feedback"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseFeedback} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmitFeedback} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default Home;
