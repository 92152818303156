import React, { useState, useEffect } from 'react';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { TextField, Button, Paper, Typography, Container, Box, CssBaseline, Avatar, FormControlLabel, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions, Link } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { db } from '../firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import axios from 'axios';

const theme = createTheme({
    palette: {
        primary: {
            main: '#14151c',
        },
        secondary: {
            main: '#1b1c27', 
        },
    },
});

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [consent, setConsent] = useState(false);
    const [open, setOpen] = useState(false);
    const [mobileWarning, setMobileWarning] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setMobileWarning(true);
        }
    }, []);

    const fetchIPAndLocation = async () => {
        try {
            const response = await axios.get('https://ipapi.co/json/');
            return {
                ip: response.data.ip,
                location: `${response.data.city}, ${response.data.region}, ${response.data.country_name}`
            };
        } catch (error) {
            console.error("Failed to fetch IP and location:", error);
            return { ip: 'N/A', location: 'N/A' };
        }
    };

    const handleLogin = async (event) => {
        event.preventDefault();
        if (!consent) {
            alert("You must agree to the terms and conditions.");
            return;
        }
        const auth = getAuth();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            
            const { ip, location } = await fetchIPAndLocation();

            const session = {
                uid: user.uid,
                email: user.email,
                time: new Date().toISOString(),
                ip: ip,
                location: location
            };
            await addDoc(collection(db, 'loginSessions'), session);

            if (mobileWarning) {
                alert("Warning: This site has some compatibility issues with mobile devices and is currently under development! Full support is available from a desktop device.");
            }

            navigate('/');
        } catch (error) {
            alert("Failed to log in: " + error.message);
        }
    };

    const handleClickOpen = (event) => {
        event.preventDefault();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 10, 
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: '#f5f5f5', 
                        padding: 3,
                        borderRadius: 2,
                        boxShadow: 1,
                    }}
                >
                    <Avatar sx={{ m: 1, mb: 2, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" fontWeight="regular" variant="h4">
                        myHOA
                    </Typography>
                    <Paper elevation={6} sx={{ p: 4, mt: 3, width: '100%', backgroundColor: '#FDFDFD' }}>
                        <Box component="form" onSubmit={handleLogin} noValidate autoComplete="off" sx={{ mt: 1 }}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Username"
                                name="email"
                                autoComplete="off"
                                autoFocus
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="off"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                            <Box sx={{ mt: 2 }}>
                            <FormControlLabel
    control={<Checkbox checked={consent} color="primary" onChange={() => setConsent(!consent)} />}
    label={
        <Typography variant="body2" component="span" sx={{ display: 'flex', alignItems: 'center' }}>
            I agree to these&nbsp;
            <Link component="button" onClick={handleClickOpen} sx={{ display: 'inline', padding: 0, minWidth: 'auto' }}>
                terms.
            </Link>
        </Typography>
    }
    sx={{ paddingRight: 2, color: 'text.primary' }} 
/>

                            </Box>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Sign In
                            </Button>
                        </Box>
                    </Paper>
                </Box>
                <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                    <DialogTitle>Terms and Conditions</DialogTitle>
                    <DialogContent>
                        <iframe src="/terms.pdf" width="100%" height="600px" style={{ border: 'none' }} title="Terms and Conditions" />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </ThemeProvider>
    );
};

export default Login;
