import React, { useState, useEffect, useCallback, useRef } from 'react';
import { format } from 'date-fns';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Tooltip, Container, CircularProgress, TextField, InputAdornment } from '@mui/material';
import { collection, getDocs, query, where, doc, getDoc, setDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Checkbox, Typography, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import InfoIcon from '@mui/icons-material/Info';
import { db } from '../firebaseConfig';

const Payments = () => {
  const pressTimer = useRef(null);
  const paymentAmountRef = useRef(null);
  const [residents, setResidents] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [selectedResident, setSelectedResident] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState('300'); // Default payment amount
  const [checkNumber, setCheckNumber] = useState('');
  const [period, setPeriod] = useState({ start: new Date(), end: new Date() });
  const [loading, setLoading] = useState(false);

  const initializePeriod = useCallback((year, periodHalf) => {
    const start = new Date(year, periodHalf === 1 ? 0 : 6, 1);
    const end = new Date(year, periodHalf === 1 ? 5 : 11, 30);
    setPeriod({ start, end });
    fetchResidentsAndPayments(start, end, true);
  }, []);

  useEffect(() => {
    const today = new Date();
    initializePeriod(today.getFullYear(), today.getMonth() < 6 ? 1 : 2);
  }, [initializePeriod]);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const handleMouseDown = () => {
    pressTimer.current = window.setTimeout(() => {
      setOpenDialog(true);
    }, 2000);
  };

  const handleMouseUp = () => {
    clearTimeout(pressTimer.current);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handlePaymentDialogClose = () => {
    setOpenPaymentDialog(false);
  };

  const clearPaymentsForPeriod = async () => {
    const paymentsQuery = query(collection(db, "payments"), where("periodStart", "==", period.start), where("periodEnd", "==", period.end));
    const paymentsSnapshot = await getDocs(paymentsQuery);
    const deletePromises = paymentsSnapshot.docs.map(doc => deleteDoc(doc.ref));
    await Promise.all(deletePromises);
    showSnackbar("All payments for the period have been cleared.");
    setOpenDialog(false);
    fetchResidentsAndPayments(period.start, period.end);
  };

  const createMissingPayments = async (start, end) => {
    if (!(start instanceof Date) || !(end instanceof Date) || isNaN(start.getTime()) || isNaN(end.getTime())) {
      console.error('Invalid dates provided:', { start, end });
      return;
    }

    setLoading(true); 

    const residentsCollection = collection(db, "residents");
    const residentsSnapshot = await getDocs(residentsCollection);
    let createdCount = 0;

    for (const residentDoc of residentsSnapshot.docs) {
      const paymentId = `${residentDoc.id}-${format(start, 'yyyy-MM')}`;
      const paymentRef = doc(db, "payments", paymentId);
      const paymentSnap = await getDoc(paymentRef);

      if (!paymentSnap.exists()) {
        await setDoc(paymentRef, {
          residentId: residentDoc.id,
          isPaid: false,
          paidDate: null,
          periodStart: start,
          periodEnd: end,
          createdAutomatically: true,
          paymentAmount: '300', // Default payment amount
          checkNumber: ''
        });
        createdCount++;
      }
    }

    setLoading(false); 

    if (createdCount > 0) {
      showSnackbar(`${createdCount} payment document(s) created for new period.`);
    } else {
      showSnackbar("No new payment documents needed.");
    }

    fetchResidentsAndPayments(start, end);
  };

  const fetchResidentsAndPayments = async (start, end) => {
    const paymentsQuery = query(collection(db, "payments"), where("periodStart", "==", start), where("periodEnd", "==", end));
    const paymentsSnapshot = await getDocs(paymentsQuery);
  
    const residentsData = await Promise.all(
      paymentsSnapshot.docs.map(async (paymentDoc) => {
        const residentRef = doc(db, "residents", paymentDoc.data().residentId);
        const residentSnap = await getDoc(residentRef);
        if (residentSnap.exists()) {
          return {
            id: residentSnap.id,
            ...residentSnap.data(),
            lotNumber: Number(residentSnap.data().lotNumber), 
            paymentId: paymentDoc.id,
            isPaid: paymentDoc.data().isPaid,
            paidDate: paymentDoc.data().paidDate?.toDate()?.toLocaleDateString(),
            onTime: paymentDoc.data().onTime,
            paymentAmount: paymentDoc.data().paymentAmount,
            checkNumber: paymentDoc.data().checkNumber
          };
        } else {
          console.error('Payment document found without a corresponding resident:', paymentDoc.id);
          return null;
        }
      })
    );
  
    // Sort residents by lotNumber in ascending order
    const sortedResidents = residentsData.filter(resident => resident !== null).sort((a, b) => a.lotNumber - b.lotNumber);
  
    setResidents(sortedResidents);
  };

  const handlePaymentStatusChange = async (resident) => {
    const paymentRef = doc(db, "payments", resident.paymentId || `new-payment-${resident.id}`);

    if (resident.isPaid) {
      const updateData = {
        isPaid: false,
        paidDate: null,
        paymentAmount: '',
        checkNumber: ''
      };
      await setDoc(paymentRef, updateData, { merge: true });
    } else {
      setSelectedResident(resident);
      setPaymentAmount(resident.paymentAmount || '300'); // Default payment amount
      setOpenPaymentDialog(true);
    }

    fetchResidentsAndPayments(period.start, period.end);
  };

  const handlePrevNextPeriod = (direction) => {
    const currentYear = period.start.getFullYear();
    const newYear = direction === 'next' ? (period.start.getMonth() === 0 ? currentYear : currentYear + 1) : (period.start.getMonth() === 0 ? currentYear - 1 : currentYear);
    const periodHalf = period.start.getMonth() === 0 ? 2 : 1;
    initializePeriod(newYear, periodHalf);
  };

  const exportToPDF = (currentPeriod = false) => {
    const doc = new jsPDF({ orientation: 'landscape' });
    const tableColumn = ["Resident Name", "Property Address", "Paid Date", "Payment Amount", "Check Number", "Lot Number"];
    const tableRows = [];
  
    residents.forEach(resident => {
      const residentData = [
        `${resident.firstName} ${resident.lastName}`,
        resident.propertyAddress,
        resident.isPaid ? resident.paidDate : "Not Paid",
        resident.paymentAmount || "N/A",
        resident.checkNumber || "N/A",
        resident.lotNumber
      ];
      tableRows.push(residentData);
    });
  
    doc.text("Tuscany Manor Payment Records", 14, 15);
  
    if (currentPeriod) {
      doc.text(`Payment Period: ${format(period.start, 'MMMM dd, yyyy')} to ${format(period.end, 'MMMM dd, yyyy')}`, 14, 25);
    }
  
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 35,
      theme: 'striped',
      styles: { fontSize: 10, cellPadding: 3 },
      headStyles: { fillColor: [26,29,36] }
    });
  
    const startDateFormatted = format(period.start, 'MM-dd-yyyy');
    const endDateFormatted = format(period.end, 'MM-dd-yyyy');
    const filename = `payment_records_${startDateFormatted}_to_${endDateFormatted}.pdf`;
    doc.save(filename);
  };
  

  const handlePaymentDialogSubmit = async () => {
    console.log("Submitting payment:", { paymentAmount, checkNumber });
    const paymentRef = doc(db, "payments", selectedResident.paymentId || `new-payment-${selectedResident.id}`);
    const updateData = {
      isPaid: true,
      paidDate: serverTimestamp(),
      paymentAmount: paymentAmount,
      checkNumber: checkNumber
    };
    await setDoc(paymentRef, updateData, { merge: true });
    handlePaymentDialogClose();
    fetchResidentsAndPayments(period.start, period.end);

    // Reset the state after submission
    setPaymentAmount('300');
    setCheckNumber('');
  };

  const handleInfoClick = (resident) => {
    console.log("Opening info dialog for resident:", resident);
    setSelectedResident(resident);
    setPaymentAmount(resident.paymentAmount || '300'); // Default payment amount
    setCheckNumber(resident.checkNumber || '');
    setOpenPaymentDialog(true);
  };

  useEffect(() => {
    if (openPaymentDialog && paymentAmountRef.current) {
      paymentAmountRef.current.focus();
      paymentAmountRef.current.setSelectionRange(paymentAmountRef.current.value.length, paymentAmountRef.current.value.length);
    }
  }, [openPaymentDialog]);

  return (
    <Container>
          <Typography variant="h4" component="h1" gutterBottom mt={2} mb={0} sx={{marginLeft: 2.2}}>HOA Dues</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '20px' }} >
        <Typography variant="h6">
          {format(period.start, 'MMMM dd, yyyy')} to {format(period.end, 'MMMM dd, yyyy')}
        </Typography>
        <Box>
          <Tooltip title="Previous Period">
            <Button variant="outlined" color="primary" onClick={() => handlePrevNextPeriod('prev')} startIcon={<ArrowBackIosIcon />} sx={{ marginRight: '10px' }}>
              Prev
            </Button>
          </Tooltip>
          <Tooltip title="Next Period">
            <Button variant="outlined" color="primary" onClick={() => handlePrevNextPeriod('next')} startIcon={<ArrowForwardIosIcon />} sx={{ marginRight: '10px' }}>
              Next
            </Button>
          </Tooltip>
          <Tooltip title="Hold for 3 seconds to clear records for this period">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => createMissingPayments(period.start, period.end)}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}>
              <AddIcon sx={{ marginRight: '8px' }} />Create or Clear Payments
            </Button>
          </Tooltip>
          <Dialog
            open={openDialog}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to clear all payments for this period?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose}>Cancel</Button>
              <Button onClick={clearPaymentsForPeriod} autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
      <Paper sx={{ margin: '20px', overflowX: 'auto' }}>
        <TableContainer>
          <Table aria-label="payments table">
            <TableHead>
              <TableRow>
                <TableCell>Resident Name</TableCell>
                <TableCell>Lot Number</TableCell>
                <TableCell>Paid</TableCell>
                <TableCell>Paid Date</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {residents.map((resident) => (
                <TableRow key={resident.id}>
                  <TableCell>{resident.firstName} {resident.lastName}</TableCell>
                  <TableCell>{resident.lotNumber}</TableCell>
                  <TableCell>
                    <Checkbox
                      checked={resident.isPaid}
                      onChange={() => handlePaymentStatusChange(resident)}
                      color="primary" />
                  </TableCell>
                  <TableCell>{resident.paidDate}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleInfoClick(resident)}>
                      <InfoIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Box sx={{ display: 'flex', justifyContent: 'center', margin: '20px' }}>
        <Tooltip title="Export Current Payment Period as PDF">
          <Button variant="outlined" color="secondary" onClick={() => exportToPDF(true)}>
            <DownloadIcon sx={{ marginRight: '8px' }} />Export Current Payment Period
          </Button>
        </Tooltip>
      </Box>
      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px' }}>
          <CircularProgress />
        </Box>
      )}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="info" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog open={openPaymentDialog} onClose={handlePaymentDialogClose}>
        <DialogTitle>Payment Information</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Payment Amount"
            type="number"
            fullWidth
            variant="standard"
            value={paymentAmount}
            onChange={(e) => setPaymentAmount(e.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            inputRef={paymentAmountRef}
          />
          <TextField
            margin="dense"
            label="Check Number"
            type="text"
            fullWidth
            variant="standard"
            value={checkNumber}
            onChange={(e) => setCheckNumber(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePaymentDialogClose}>Cancel</Button>
          <Button onClick={handlePaymentDialogSubmit}>Save</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Payments;
