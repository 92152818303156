import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebaseConfig';
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { collection, getDocs, writeBatch } from 'firebase/firestore';
import { Button, TextField, Container, Box, Table, TableBody, TableCell, TableHead, TableRow, Paper, CircularProgress, Snackbar, Alert } from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const Admin = () => {
  const [adminEmail, setAdminEmail] = useState('');
  const [adminPassword, setAdminPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loggingIn, setLoggingIn] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
        fetchSessions().finally(() => setLoading(false));
      } else {
        setIsLoggedIn(false);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchSessions = async () => {
    try {
      const sessionsCollection = collection(db, 'loginSessions');
      const sessionsSnapshot = await getDocs(sessionsCollection);
      const sessionsList = sessionsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Sort sessions by time in descending order
      const sortedSessions = sessionsList.sort((a, b) => new Date(b.time) - new Date(a.time));
      setSessions(sortedSessions);
    } catch (error) {
      console.error('Error fetching sessions:', error);
      setSnackbarMessage('Error fetching sessions');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleLogin = async () => {
    setLoggingIn(true);
    try {
      await signInWithEmailAndPassword(auth, adminEmail, adminPassword);
      setSnackbarMessage('Login successful');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Login failed: ' + error.message);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setLoggingIn(false);
    }
  };

  const handleClearSessions = async () => {
    setLoading(true);
    try {
      const batch = writeBatch(db);
      const sessionsCollection = collection(db, 'loginSessions');
      const sessionsSnapshot = await getDocs(sessionsCollection);
      sessionsSnapshot.docs.forEach(doc => batch.delete(doc.ref));
      await batch.commit();
      fetchSessions();
      setSnackbarMessage('Sessions cleared successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error clearing sessions:', error);
      setSnackbarMessage('Error clearing sessions');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const exportToPDF = () => {
    const doc = new jsPDF({ orientation: 'landscape' });
    const tableColumn = ["Email", "Time", "IP", "Location"];
    const tableRows = [];
  
    sessions.forEach((session) => {
      const formattedTime = new Date(session.time).toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
      });
      const sessionData = [
        session.email,
        formattedTime,
        session.ip,
        session.location,
      ];
      tableRows.push(sessionData);
    });
  
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 10,
      theme: 'striped',
      styles: { fontSize: 10, cellPadding: 3 },
      headStyles: { fillColor: [26,29,36] }
    });
  
    doc.save('login_sessions.pdf');
  };
  

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (!isLoggedIn) {
    return (
      <Container>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
          <TextField label="Email" type="email" value={adminEmail} onChange={(e) => setAdminEmail(e.target.value)} />
          <TextField label="Password" type="password" value={adminPassword} onChange={(e) => setAdminPassword(e.target.value)} />
          <Button variant="contained" color="primary" onClick={handleLogin} disabled={loggingIn}>
            {loggingIn ? <CircularProgress size={24} /> : 'Login'}
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Box display="flex" justifyContent="flex-start" alignItems="center" m={2} mb={3} gap={2}>
        <Button variant="outlined" color="primary" onClick={exportToPDF}>Export All Login Session Data</Button>
        <Button variant="outlined" color="secondary" onClick={handleClearSessions}>Clear Sessions</Button>
      </Box>
      <Paper style={{ margin: '20px', overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>IP</TableCell>
              <TableCell>Location</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sessions.length > 0 ? (
              sessions.map(session => (
                <TableRow key={session.id}>
                  <TableCell>{session.email}</TableCell>
                  <TableCell>{new Date(session.time).toLocaleString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true,
                  })}</TableCell>
                  <TableCell>{session.ip}</TableCell>
                  <TableCell>{session.location}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">No sessions available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Admin;
