import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import { collection, addDoc, getDocs, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Table, TableBody, TableCell, TableHead, TableRow, Paper, Box, Container,
  IconButton, MenuItem, Select, Typography, FormControl, InputLabel, FormControlLabel, Checkbox, Menu, List, ListItem
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const Residents = () => {
  const [open, setOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [residents, setResidents] = useState([]);
  const [currentResident, setCurrentResident] = useState({
    firstName: '',
    lastName: '',
    spouseName: '',
    propertyAddress: '',
    mailingAddress: '',
    lotNumber: '',
    email: '',
    phoneNumber: '',
    accountNumber: '',
  });
  const [isMailingSameAsProperty, setIsMailingSameAsProperty] = useState(true);
  const [displayAddressType, setDisplayAddressType] = useState('propertyAddress');
  const [sortCriteria, setSortCriteria] = useState('lotNumber');
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState({
    firstName: true,
    lastName: true,
    spouseName: false,
    propertyAddress: true,
    mailingAddress: true,
    lotNumber: true,
    email: false,
    phoneNumber: true,
    accountNumber: true,
  });

  useEffect(() => {
    fetchResidents();
  }, []);

  const fetchResidents = async () => {
    const residentsCollection = collection(db, "residents");
    const residentsSnapshot = await getDocs(residentsCollection);
    const residentsList = residentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setResidents(residentsList);
  };

  const handleOpen = (resident = null) => {
    if (resident) {
      setCurrentResident(resident);
      setEditing(true);
      setIsMailingSameAsProperty(resident.propertyAddress === resident.mailingAddress);
    } else {
      setCurrentResident({
        firstName: '',
        lastName: '',
        spouseName: '',
        propertyAddress: '',
        mailingAddress: '',
        lotNumber: '',
        email: '',
        phoneNumber: '',
        accountNumber: '',
      });
      setEditing(false);
      setIsMailingSameAsProperty(true);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phoneNumber') {
      const formattedPhoneNumber = formatPhoneNumber(value);
      setCurrentResident(prev => ({
        ...prev,
        [name]: formattedPhoneNumber
      }));
    } else {
      setCurrentResident(prev => ({
        ...prev,
        [name]: value
      }));
    }
    if (name === 'propertyAddress' && isMailingSameAsProperty) {
      setCurrentResident(prev => ({
        ...prev,
        mailingAddress: value
      }));
    }
  };

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setIsMailingSameAsProperty(checked);
    if (checked) {
      setCurrentResident(prev => ({
        ...prev,
        mailingAddress: prev.propertyAddress
      }));
    }
  };

  const handleSubmit = async () => {
    if (!currentResident.firstName) {
      alert('Please fill in the first name.');
      return;
    }
    try {
      if (editing) {
        const docRef = doc(db, "residents", currentResident.id);
        await updateDoc(docRef, currentResident);
      } else {
        await addDoc(collection(db, "residents"), currentResident);
      }
      fetchResidents();
      handleClose();
    } catch (error) {
      console.error("Error adding/updating resident: ", error);
      alert("Error adding/updating resident: " + error.message);
    }
  };

  const handleDeleteResident = async () => {
    if (currentResident.id) {
      try {
        await deleteDoc(doc(db, "residents", currentResident.id));
        fetchResidents();
        handleClose();
        console.log("Resident deleted successfully");
      } catch (error) {
        console.error("Error deleting resident: ", error);
        alert("Error deleting resident: " + error.message);
      }
    }
  };

  const handleSortChange = (event) => {
    setSortCriteria(event.target.value);
  };

  const sortResidents = (residents, criteria) => {
    return [...residents].sort((a, b) => {
      if (criteria === 'lotNumber') {
        return parseInt(a[criteria]) - parseInt(b[criteria]);
      }
      if (a[criteria] < b[criteria]) return -1;
      if (a[criteria] > b[criteria]) return 1;
      return 0;
    });
  };

  const sortedResidents = sortResidents(residents, sortCriteria);

  const toggleDisplayAddressType = () => {
    setDisplayAddressType(prev => (prev === 'propertyAddress' ? 'mailingAddress' : 'propertyAddress'));
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (event) => {
    setFilters(prev => ({
      ...prev,
      [event.target.name]: event.target.checked
    }));
  };

  const exportToPDF = () => {
    const doc = new jsPDF({ orientation: 'landscape' });
    const tableColumn = [
      "Lot Number",
      "First Name",
      "Last Name",
      "Spouse Name",
      "Property Address",
      "Mailing Address",
      "Email",
      "Phone Number",
      "Account Number"
    ];
    const tableRows = [];
  
    sortedResidents.forEach(resident => {
      const residentData = [
        resident.lotNumber,
        resident.firstName,
        resident.lastName,
        resident.spouseName,
        resident.propertyAddress,
        resident.mailingAddress,
        resident.email,
        formatPhoneNumber(resident.phoneNumber),
        resident.accountNumber
      ];
      tableRows.push(residentData);
    });
  
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
    const filenameDate = formattedDate.replace(/\//g, '');
  
    doc.text(`Tuscany Residents - ${formattedDate}`, 14, 15);
  
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 25,
      theme: 'striped',
      styles: { fontSize: 10, cellPadding: 3 },
      headStyles: { fillColor: [26, 29, 36] }
    });
  
    doc.save(`Tuscany_Residents_${filenameDate}.pdf`);
  };
  
  
  
  const isMenuOpen = Boolean(anchorEl);

  return (
    <Container>
    <Typography variant="h4" component="h1" gutterBottom mt={4} mb={3} sx={{ marginLeft: 2 }}>Resident Management</Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" m={2}>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <Button variant="outlined" color="primary" onClick={() => handleOpen()} sx={{ marginRight: 3,marginLeft:.5 }}>
            Add New Primary Resident
          </Button>
          <FormControl variant="outlined" size="small" sx={{ marginRight: 2 }}>
            <InputLabel>Sort By</InputLabel>
            <Select
              value={sortCriteria}
              onChange={handleSortChange}
              label="Sort By"
            >
              <MenuItem value="firstName">First Name</MenuItem>
              <MenuItem value="lastName">Last Name</MenuItem>
              <MenuItem value="lotNumber">Lot Number</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <IconButton onClick={handleFilterClick}>
          <FilterListIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleFilterClose}>
          <List>
            {Object.keys(filters).map((filter) => (
              <ListItem key={filter}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters[filter]}
                      onChange={handleFilterChange}
                      name={filter}
                    />
                  }
                  label={filter.replace(/([A-Z])/g, ' $1').charAt(0).toUpperCase() + filter.slice(1)}
                />
              </ListItem>
            ))}
          </List>
        </Menu>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editing ? 'Edit Resident' : 'Add New Resident'}</DialogTitle>
        <DialogContent>
          <TextField autoFocus margin="dense" name="firstName" label="First Name" type="text" fullWidth value={currentResident.firstName} onChange={handleInputChange} />
          <TextField margin="dense" name="lastName" label="Last Name" type="text" fullWidth value={currentResident.lastName} onChange={handleInputChange} />
          <TextField margin="dense" name="spouseName" label="Spouse Name" type="text" fullWidth value={currentResident.spouseName} onChange={handleInputChange} />
          <TextField margin="dense" name="propertyAddress" label="Property Address" type="text" fullWidth value={currentResident.propertyAddress} onChange={handleInputChange} />
          <FormControlLabel
            control={<Checkbox checked={isMailingSameAsProperty} onChange={handleCheckboxChange} />}
            label="Mailing address is the same as property address"
          />
          {!isMailingSameAsProperty && (
            <TextField margin="dense" name="mailingAddress" label="Mailing Address" type="text" fullWidth value={currentResident.mailingAddress} onChange={handleInputChange} />
          )}
          <TextField margin="dense" name="lotNumber" label="Lot Number" type="text" fullWidth value={currentResident.lotNumber} onChange={handleInputChange} />
          <TextField margin="dense" name="email" label="Email" type="email" fullWidth value={currentResident.email} onChange={handleInputChange} />
          <TextField margin="dense" name="phoneNumber" label="Phone Number" type="tel" fullWidth value={currentResident.phoneNumber} onChange={handleInputChange} />
          <TextField margin="dense" name="accountNumber" label="Account Number" type="text" fullWidth value={currentResident.accountNumber} onChange={handleInputChange} />
        </DialogContent>
        <DialogActions>
          {editing && (
            <IconButton onClick={handleDeleteResident} style={{ color: '#14151c' }}>
              <DeleteIcon />
            </IconButton>
          )}
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">{editing ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>
      <Paper style={{ margin: '20px', overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              {filters.firstName && <TableCell>First</TableCell>}
              {filters.lastName && <TableCell>Last</TableCell>}
              {filters.spouseName && <TableCell>Spouse</TableCell>}
              {filters[displayAddressType] && (
                <TableCell onClick={toggleDisplayAddressType} style={{ cursor: 'pointer' }}>
                  {displayAddressType === 'propertyAddress' ? '▼ Property Address' : '▲ Mailing Address'}
                </TableCell>
              )}
              {filters.lotNumber && <TableCell>Lot</TableCell>}
              {filters.email && <TableCell>Email</TableCell>}
              {filters.phoneNumber && <TableCell>Phone</TableCell>}
              {filters.accountNumber && <TableCell>Account</TableCell>}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedResidents.map(resident => (
              <TableRow key={resident.id}>
                {filters.firstName && <TableCell>{resident.firstName}</TableCell>}
                {filters.lastName && <TableCell>{resident.lastName}</TableCell>}
                {filters.spouseName && <TableCell>{resident.spouseName}</TableCell>}
                {filters[displayAddressType] && <TableCell>{resident[displayAddressType]}</TableCell>}
                {filters.lotNumber && <TableCell>{resident.lotNumber}</TableCell>}
                {filters.email && <TableCell>{resident.email}</TableCell>}
                {filters.phoneNumber && <TableCell>{formatPhoneNumber(resident.phoneNumber)}</TableCell>}
                {filters.accountNumber && <TableCell>{resident.accountNumber}</TableCell>}
                <TableCell>
                  <Button sx={{ color: '#121b23' }} onClick={() => handleOpen(resident)}>Edit</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Box display="flex" justifyContent="flex-end" m={2}>
        <Button variant="outlined" color="primary" onClick={exportToPDF}>
          Export to PDF
        </Button>
      </Box>
    </Container>
  );
};

export default Residents;
