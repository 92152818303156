import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FDFDFD', // Light background color
    padding: 60,
  },
  logo: {
    width: 40,
    height: 40,
    marginBottom: 20,
  },
  header: {
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 40,
    color: '#000000', // Dark text color
  },
  text: {
    margin: 5,
    fontSize: 14,
    textAlign: 'left',
    color: '#333333', // Dark text color
  },
  smallText: {
    fontSize: 10,
    textAlign: 'left',
    color: '#333333', // Dark text color
  },
  bold: {
    fontWeight: 'bold'
  },
  table: {
    marginTop: 20,
    borderTop: '2 solid #000000', // Dark border color
    borderBottom: '2 solid #000000', // Dark border color
  },
  tableRow: {
    flexDirection: 'row',
    borderBottom: '1 solid #cccccc',
  },
  tableColHeader: {
    width: '50%',
    padding: 10,
    fontWeight: 'bold',
    backgroundColor: '#f4f4f4', // Light background color for table header
    color: '#000000', // Dark text color for table header
  },
  tableCol: {
    width: '50%',
    padding: 10,
    color: '#333333', // Dark text color
  },
  footer: {
    marginTop: 'auto',
    padding: 10,
  }
});

// Create Document Component
const InvoicePDF = ({ resident, dueDate, amount }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.header}>Semi-Annual Homeowners Association Dues</Text>
        <Text style={[styles.text, styles.bold]}>
          Invoice for: {resident.firstName} {resident.lastName}
          {resident.spouseName ? `, ${resident.spouseName} ${resident.lastName}` : ''}
        </Text>
        <Text style={styles.text}>Property Address: {resident.propertyAddress}</Text>
        <Text style={styles.text}>Mailing Address: {resident.mailingAddress}</Text>
        <Text style={styles.text}>Bill To: {resident.email}</Text>
        <Text style={styles.text}>Account # {resident.accountNumber}</Text>
        <Text style={styles.text}>Issue Date: {new Date().toLocaleDateString()}</Text>
        <Text style={styles.text}>Description: {"Tuscany HOA Dues"}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.tableColHeader}>Due Date: {dueDate}</Text>
            <Text style={styles.tableColHeader}>Amount: ${amount}</Text>
          </View>
        </View>
        {/* Footer view for small text */}
        <View style={styles.footer}>
          <Text style={styles.smallText}>For Online Bill Pay Banking Setup:</Text>
          <Text style={styles.smallText}>1. Company / Payee Name: Tuscany HOA</Text>
          <Text style={styles.smallText}>2. Customer Account #: {resident.accountNumber}</Text>
          <Text style={styles.smallText}>3. Property Address: {resident.propertyAddress}</Text>
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePDF;
