import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider, CssBaseline, Box, CircularProgress } from '@mui/material';
import { isMobile } from './deviceUtils';
import Residents from './components/Residents';
import Home from './components/Home';
import Payments from './components/Payments';
import MobilePayments from './components/MobilePayments'; // Importing the new MobilePayments component
import Invoices from './components/Invoices';
import Login from './components/Login';
import Sidebar from './components/Sidebar';
import { AuthProvider, useAuth } from './AuthContext';
import ProtectedRoute from './ProtectedRoute';
import Admin from './components/Admin';
import Terms from './components/Terms';
import Profiles from './components/Profiles';
import BillsPayable from './components/BillsPayable';
import Ledger from './components/Ledger';
import MobileLedger from './components/MobileLedger';

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#14151c',
      contrastText: '#ebebec',
    },
    secondary: {
      main: '#14151c',
    },
    background: {
      default: '#f4f4f4',
      paper: '#ffffff',
      window: '#14151c',
    },
    text: {
      primary: '#14151c',
      secondary: '#646464',
    },
  },
});

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Layout />
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

const Layout = () => {
  const { user, isAdmin, loading } = useAuth();
  const navigate = useNavigate();
  const [typedKeys, setTypedKeys] = useState('');

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && typedKeys === 'admin' && isAdmin) {
        navigate('/admin');
      } else {
        setTypedKeys((prev) => (prev + event.key).slice(-5)); // Keep only the last 5 characters
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [typedKeys, isAdmin, navigate]);

  if (loading) {
    return (
      <Box component="main" sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!user) {
    return (
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Box>
    );
  }

  return (
    <>
      {isMobile() ? (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Routes>
            <Route path="/profiles" element={<Profiles />} />
            <Route path="/mobileledger" element={<MobileLedger />} />
            <Route path="/mobilepayments" element={<MobilePayments />} /> {/* Added MobilePayments Route */}
            <Route path="*" element={<Navigate to="/profiles" />} />
          </Routes>
        </Box>
      ) : (
        <>
          {user && <Sidebar />}
          <Box component="main" sx={{ flexGrow: 1, p: 3, marginLeft: user ? '240px' : '0px' }}>
            <Routes>
              <Route path="/login" element={user ? <Navigate replace to="/" /> : <Login />} />
              <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
              <Route path="/residents" element={<ProtectedRoute><Residents /></ProtectedRoute>} />
              <Route path="/payments" element={<ProtectedRoute><Payments /></ProtectedRoute>} />
              <Route path="/invoices" element={<ProtectedRoute><Invoices /></ProtectedRoute>} />
              <Route path="/bills-payable" element={<ProtectedRoute><BillsPayable /></ProtectedRoute>} />
              <Route path="/ledger" element={<ProtectedRoute><Ledger /></ProtectedRoute>} />
              <Route path="/admin" element={isAdmin ? <Admin /> : <Navigate to="/" />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Box>
        </>
      )}
    </>
  );
};

export default App;
