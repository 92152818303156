import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext'; // Ensure this path is correct
import { db } from '../firebaseConfig';
import { collection, getDocs, updateDoc, doc, addDoc, deleteDoc } from 'firebase/firestore';
import { AppBar, Toolbar, IconButton, Typography, Card, CardContent, CardActions, Button, Collapse, Container, Box, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle, TextField, InputBase } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { alpha, styled } from '@mui/material/styles';
import VCard from 'vcard-creator';

// Styled component for the search bar
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(3),
  width: 'auto',
  [theme.breakpoints.down('xs')]: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const Profiles = () => {
  const [profiles, setProfiles] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [currentProfile, setCurrentProfile] = useState({});
  const [profileToDelete, setProfileToDelete] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const { logout, isAdmin } = useAuth();

  useEffect(() => {
    const fetchProfiles = async () => {
      const profilesCollection = collection(db, 'residents');
      const profilesSnapshot = await getDocs(profilesCollection);
      const profilesList = profilesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProfiles(profilesList);
    };

    fetchProfiles();
  }, []);

  const handleExpandClick = (id) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [id]: !prevExpanded[id],
    }));
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  const handleEditClick = (profile) => {
    setCurrentProfile(profile);
    setEditDialogOpen(true);
  };

  const handleDialogClose = () => {
    setEditDialogOpen(false);
    setAddDialogOpen(false);
    setConfirmDeleteOpen(false);
    setCurrentProfile({});
    setProfileToDelete(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    const profileRef = doc(db, 'residents', currentProfile.id);
    await updateDoc(profileRef, currentProfile);
    setProfiles((prevProfiles) =>
      prevProfiles.map((profile) =>
        profile.id === currentProfile.id ? currentProfile : profile
      )
    );
    setEditDialogOpen(false);
  };

  const handleAddNew = async () => {
    const newProfile = { ...currentProfile };
    const docRef = await addDoc(collection(db, 'residents'), newProfile);
    newProfile.id = docRef.id;
    setProfiles((prevProfiles) => [...prevProfiles, newProfile]);
    setAddDialogOpen(false);
    setCurrentProfile({});
  };

  const handleConfirmDelete = (profile) => {
    setProfileToDelete(profile);
    setConfirmDeleteOpen(true);
  };

  const handleDelete = async () => {
    await deleteDoc(doc(db, 'residents', profileToDelete.id));
    setProfiles((prevProfiles) => prevProfiles.filter((profile) => profile.id !== profileToDelete.id));
    setConfirmDeleteOpen(false);
    setProfileToDelete(null);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredProfiles = profiles.filter((profile) =>
    `${profile.firstName} ${profile.lastName}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  const createVCard = (profile) => {
    const vCard = new VCard();
    vCard.addName(profile.lastName, profile.firstName);
    vCard.addEmail(profile.email);
    vCard.addPhoneNumber(profile.phoneNumber);
    vCard.addAddress('', '', profile.propertyAddress, '', '', '', '');
    return vCard.toString();
  };

  const handleAddToContacts = (profile) => {
    const vCardString = createVCard(profile);
    const blob = new Blob([vCardString], { type: 'text/vcard' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${profile.firstName}_${profile.lastName}.vcf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const menuItems = [
    { text: 'HOA Dues', path: '/mobilepayments', onClick: () => navigate('/mobilepayments') },
    { text: 'Ledger', path: '/mobileledger', onClick: () => navigate('/mobileledger') },
    { text: 'Logout', onClick: handleLogout },
  ];

  return (
    <Container>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuOpen}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            myHOA
          </Typography>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </Search>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {menuItems.map((item, index) => (
          <MenuItem key={index} onClick={item.onClick}>
            {item.text}
          </MenuItem>
        ))}
      </Menu>
      <Box mt={10}>
      <Typography variant="h4" component="h1" gutterBottom mt={4}>
                    Neighborhood Directory
                </Typography>
        {isAdmin && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setAddDialogOpen(true)}
            startIcon={<AddIcon />}
            sx={{ mb: 2 }}
          >
            Add New Resident
          </Button>
        )}
        {filteredProfiles.map((profile) => (
          <Card key={profile.id} sx={{ marginBottom: 2 }}>
            <CardContent>
              <Typography variant="h5">
                {profile.firstName} {profile.lastName}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {profile.propertyAddress}
              </Typography>
            </CardContent>
            <CardActions>
              <Button onClick={() => handleExpandClick(profile.id)} aria-expanded={expanded[profile.id]} aria-label="show more">
                <ExpandMoreIcon />
              </Button>
              <Button onClick={() => handleAddToContacts(profile)} color="primary">
                Add to Contacts
              </Button>
            </CardActions>
            <Collapse in={expanded[profile.id]} timeout="auto" unmountOnExit>
              <CardContent>
                <Typography paragraph>Spouse: {profile.spouseName}</Typography>
                <Typography paragraph>
                  Email: <a href={`mailto:${profile.email}`}>{profile.email}</a>
                </Typography>
                <Typography paragraph>
                  Phone: <a href={`tel:${profile.phoneNumber}`}>{profile.phoneNumber}</a>
                </Typography>
                <Typography paragraph>Account Number: {profile.accountNumber}</Typography>
                {isAdmin && (
                  <>
                    <Button onClick={() => handleEditClick(profile)} color="primary">
                      Edit
                    </Button>
                    <Button onClick={() => handleConfirmDelete(profile)} color="secondary">
                      Delete
                    </Button>
                  </>
                )}
              </CardContent>
            </Collapse>
          </Card>
        ))}
      </Box>

      <Dialog open={editDialogOpen} onClose={handleDialogClose} fullWidth>
        <DialogTitle>Edit Resident</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="firstName"
            label="First Name"
            type="text"
            fullWidth
            value={currentProfile.firstName || ''}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="lastName"
            label="Last Name"
            type="text"
            fullWidth
            value={currentProfile.lastName || ''}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="spouseName"
            label="Spouse Name"
            type="text"
            fullWidth
            value={currentProfile.spouseName || ''}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="propertyAddress"
            label="Property Address"
            type="text"
            fullWidth
            value={currentProfile.propertyAddress || ''}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="mailingAddress"
            label="Mailing Address"
            type="text"
            fullWidth
            value={currentProfile.mailingAddress || ''}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="lotNumber"
            label="Lot Number"
            type="text"
            fullWidth
            value={currentProfile.lotNumber || ''}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            value={currentProfile.email || ''}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="phoneNumber"
            label="Phone Number"
            type="tel"
            fullWidth
            value={currentProfile.phoneNumber || ''}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="accountNumber"
            label="Account Number"
            type="text"
            fullWidth
            value={currentProfile.accountNumber || ''}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={addDialogOpen} onClose={handleDialogClose} fullWidth>
        <DialogTitle>Add New Resident</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="firstName"
            label="First Name"
            type="text"
            fullWidth
            value={currentProfile.firstName || ''}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="lastName"
            label="Last Name"
            type="text"
            fullWidth
            value={currentProfile.lastName || ''}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="spouseName"
            label="Spouse Name"
            type="text"
            fullWidth
            value={currentProfile.spouseName || ''}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="propertyAddress"
            label="Property Address"
            type="text"
            fullWidth
            value={currentProfile.propertyAddress || ''}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="mailingAddress"
            label="Mailing Address"
            type="text"
            fullWidth
            value={currentProfile.mailingAddress || ''}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="lotNumber"
            label="Lot Number"
            type="text"
            fullWidth
            value={currentProfile.lotNumber || ''}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            value={currentProfile.email || ''}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="phoneNumber"
            label="Phone Number"
            type="tel"
            fullWidth
            value={currentProfile.phoneNumber || ''}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="accountNumber"
            label="Account Number"
            type="text"
            fullWidth
            value={currentProfile.accountNumber || ''}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddNew} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmDeleteOpen} onClose={handleDialogClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this profile?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Profiles;
