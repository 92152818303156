import React from 'react';
import { Container, Box, Typography } from '@mui/material';

const Terms = () => {
    return (
        <Container component="main" maxWidth="md">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    Terms and Conditions
                </Typography>
                <Box sx={{ mt: 3, width: '100%', height: '600px' }}>
                    <embed src="/path/to/terms.pdf" width="100%" height="100%" />
                </Box>
            </Box>
        </Container>
    );
};

export default Terms;
