import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs, addDoc, deleteDoc, doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Checkbox, Container, Typography, IconButton, InputAdornment } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const BillsPayable = () => {
  const [bills, setBills] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [checkNumberDialogOpen, setCheckNumberDialogOpen] = useState(false);
  const [newBill, setNewBill] = useState({
    billDate: '',
    dateDue: '',
    payableTo: '',
    amount: '',
    invoiceNumber: '', // Added invoiceNumber
    paid: false,
    datePaid: '',
  });
  const [currentBill, setCurrentBill] = useState(null);
  const [checkNumber, setCheckNumber] = useState('');

  useEffect(() => {
    const fetchBills = async () => {
      const billsCollection = collection(db, 'bills');
      const billsSnapshot = await getDocs(billsCollection);
      const billsList = billsSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          billDate: data.billDate ? data.billDate.toDate() : null,
          dateDue: data.dateDue ? data.dateDue.toDate() : null,
          datePaid: data.datePaid ? data.datePaid.toDate() : null,
        };
      });
      setBills(billsList);
    };

    fetchBills();
  }, []);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setNewBill({
      billDate: '',
      dateDue: '',
      payableTo: '',
      amount: '',
      invoiceNumber: '', // Reset invoiceNumber
      paid: false,
      datePaid: '',
    });
  };

  const handleEditDialogOpen = (bill) => {
    setCurrentBill(bill);
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setCurrentBill(null);
  };

  const handleCheckNumberDialogOpen = (bill) => {
    setCurrentBill(bill);
    setCheckNumberDialogOpen(true);
  };

  const handleCheckNumberDialogClose = () => {
    setCheckNumberDialogOpen(false);
    setCheckNumber('');
  };

  const handleAddBill = async () => {
    try {
      const billsCollection = collection(db, 'bills');
      const billData = {
        ...newBill,
        billDate: new Date(newBill.billDate),
        dateDue: new Date(newBill.dateDue),
        datePaid: newBill.datePaid ? new Date(newBill.datePaid) : null
      };
      const billDoc = await addDoc(billsCollection, billData);
      setBills([...bills, { ...billData, id: billDoc.id }]);
      handleDialogClose();
    } catch (error) {
      console.error("Error adding bill: ", error);
    }
  };

  const handleEditBill = async () => {
    try {
      const billDoc = doc(db, 'bills', currentBill.id);
      const updatedBill = {
        ...currentBill,
        billDate: new Date(currentBill.billDate),
        dateDue: new Date(currentBill.dateDue),
        datePaid: currentBill.datePaid ? new Date(currentBill.datePaid) : null
      };
      await updateDoc(billDoc, updatedBill);
      setBills(bills.map(bill => bill.id === currentBill.id ? updatedBill : bill));
      handleEditDialogClose();
    } catch (error) {
      console.error("Error updating bill: ", error);
    }
  };

  const handleDeleteBill = async (id) => {
    try {
      const billDoc = doc(db, 'bills', id);
      await deleteDoc(billDoc);
      setBills(bills.filter(bill => bill.id !== id));
    } catch (error) {
      console.error("Error deleting bill: ", error);
    }
  };

  const handleTogglePaid = (bill) => {
    if (!bill.paid) {
      handleCheckNumberDialogOpen(bill);
    } else {
      updateBillPaidStatus(bill, false, '');
    }
  };

  const updateBillPaidStatus = async (bill, paid, checkNumber) => {
    try {
      const billDoc = doc(db, 'bills', bill.id);
      const updatedBill = {
        ...bill,
        paid: paid,
        datePaid: paid ? serverTimestamp() : null,
        checkNumber: checkNumber
      };

      await updateDoc(billDoc, updatedBill);

      const newBills = bills.map(b => {
        if (b.id === bill.id) {
          return {
            ...b,
            paid: paid,
            datePaid: paid ? new Date() : null,
            checkNumber: checkNumber
          };
        }
        return b;
      });

      setBills(newBills);
      handleCheckNumberDialogClose();
    } catch (error) {
      console.error("Error updating bill: ", error);
    }
  };

  const handleSaveCheckNumber = () => {
    updateBillPaidStatus(currentBill, true, checkNumber);
  };

  const handleExportBills = () => {
    const sortedBills = [...bills].sort((a, b) => new Date(b.billDate) - new Date(a.billDate));

    const doc = new jsPDF({ orientation: 'landscape' });
    doc.text('Bills Report', 14, 16);
    doc.autoTable({
      head: [['Bill Date', 'Date Due', 'Payable To', 'Amount', 'Invoice #', 'Paid', 'Date Paid', 'Check #']],
      body: sortedBills.map(bill => [
        bill.billDate ? bill.billDate.toLocaleDateString() : '',
        bill.dateDue ? bill.dateDue.toLocaleDateString() : '',
        bill.payableTo,
        `$${parseFloat(bill.amount).toLocaleString()}`,
        bill.invoiceNumber, // Added invoiceNumber
        bill.paid ? 'Yes' : 'No',
        bill.datePaid ? bill.datePaid.toLocaleDateString() : '',
        bill.checkNumber
      ]),
      theme: 'striped',
      startY: 20,
      headStyles: { fillColor: [26, 29, 36] } // Same header color as in Payments.js
    });
    doc.save('bills_report.pdf');
  };

  const formatDateString = (date) => {
    return date ? new Date(date).toLocaleDateString() : '';
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" mt={4} mb={3} gutterBottom>Bills Payable</Typography>
      <Button variant="contained" color="primary" onClick={handleDialogOpen}>Add Bill</Button>
      <Button variant="contained" color="secondary" onClick={handleExportBills} sx={{ marginLeft: 2 }}>Export All Bills</Button>
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Add New Bill</DialogTitle>
        <DialogContent>
          <TextField label="Bill Date" type="date" fullWidth margin="dense" InputLabelProps={{ shrink: true }} value={newBill.billDate} onChange={(e) => setNewBill({ ...newBill, billDate: e.target.value })} />
          <TextField label="Date Due" type="date" fullWidth margin="dense" InputLabelProps={{ shrink: true }} value={newBill.dateDue} onChange={(e) => setNewBill({ ...newBill, dateDue: e.target.value })} />
          <TextField label="Payable To" fullWidth margin="dense" value={newBill.payableTo} onChange={(e) => setNewBill({ ...newBill, payableTo: e.target.value })} />
          <TextField
            label="Amount"
            fullWidth
            margin="dense"
            value={newBill.amount}
            onChange={(e) => setNewBill({ ...newBill, amount: e.target.value })}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
          <TextField
            label="Invoice Number"
            fullWidth
            margin="dense"
            value={newBill.invoiceNumber} // Added invoiceNumber
            onChange={(e) => setNewBill({ ...newBill, invoiceNumber: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleAddBill}>Add</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editDialogOpen} onClose={handleEditDialogClose}>
        <DialogTitle>Edit Bill</DialogTitle>
        <DialogContent>
          <TextField label="Bill Date" type="date" fullWidth margin="dense" InputLabelProps={{ shrink: true }} value={currentBill?.billDate ? currentBill.billDate.toISOString().split('T')[0] : ''} onChange={(e) => setCurrentBill({ ...currentBill, billDate: e.target.value })} />
          <TextField label="Date Due" type="date" fullWidth margin="dense" InputLabelProps={{ shrink: true }} value={currentBill?.dateDue ? currentBill.dateDue.toISOString().split('T')[0] : ''} onChange={(e) => setCurrentBill({ ...currentBill, dateDue: e.target.value })} />
          <TextField label="Payable To" fullWidth margin="dense" value={currentBill?.payableTo || ''} onChange={(e) => setCurrentBill({ ...currentBill, payableTo: e.target.value })} />
          <TextField
            label="Amount"
            fullWidth
            margin="dense"
            value={currentBill?.amount || ''}
            onChange={(e) => setCurrentBill({ ...currentBill, amount: e.target.value })}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
          <TextField
            label="Invoice Number"
            fullWidth
            margin="dense"
            value={currentBill?.invoiceNumber || ''} // Added invoiceNumber
            onChange={(e) => setCurrentBill({ ...currentBill, invoiceNumber: e.target.value })}
          />
          <TextField label="Check Number" fullWidth margin="dense" value={currentBill?.checkNumber || ''} onChange={(e) => setCurrentBill({ ...currentBill, checkNumber: e.target.value })} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose}>Cancel</Button>
          <Button onClick={handleEditBill}>Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={checkNumberDialogOpen} onClose={handleCheckNumberDialogClose}>
        <DialogTitle>Enter Check Number</DialogTitle>
        <DialogContent>
          <TextField label="Check Number" fullWidth margin="dense" value={checkNumber} onChange={(e) => setCheckNumber(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCheckNumberDialogClose}>Cancel</Button>
          <Button onClick={handleSaveCheckNumber}>Save</Button>
        </DialogActions>
      </Dialog>
      <TableContainer component={Paper} sx={{ marginTop: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Bill Date</TableCell>
              <TableCell>Date Due</TableCell>
              <TableCell>Payable To</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Invoice #</TableCell> {/* Added Invoice # column */}
              <TableCell>Paid?</TableCell>
              <TableCell>Date Paid</TableCell>
              <TableCell>Check #</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bills.map((bill) => (
              <TableRow key={bill.id}>
                <TableCell>{formatDateString(bill.billDate)}</TableCell>
                <TableCell>{formatDateString(bill.dateDue)}</TableCell>
                <TableCell>{bill.payableTo}</TableCell>
                <TableCell style={{ color: bill.paid ? 'red' : 'inherit' }}>${parseFloat(bill.amount).toLocaleString()}</TableCell>
                <TableCell>{bill.invoiceNumber}</TableCell> {/* Added Invoice # value */}
                <TableCell>
                  <Checkbox
                    checked={bill.paid}
                    onChange={() => handleTogglePaid(bill)}
                    color="primary"
                  />
                </TableCell>
                <TableCell>{formatDateString(bill.datePaid)}</TableCell>
                <TableCell>{bill.checkNumber}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEditDialogOpen(bill)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleDeleteBill(bill.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default BillsPayable;
