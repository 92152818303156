import { Drawer, List, ListItem, ListItemIcon, ListItemText, Box, Typography, Link } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import PaymentIcon from '@mui/icons-material/Payment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import '../App.css';

const Sidebar = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 240,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { 
          width: 240, 
          boxSizing: 'border-box', 
          backgroundColor: 'var(--sidebar-bg-color)', 
          color: 'var(--text-color)', 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center',
          overflowX: 'hidden'
        },
      }}
      className="sidebar-root"
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '30px 0',
          overflowX: 'hidden'
        }}
      >
        <img src={`${process.env.PUBLIC_URL}/logo2g.png`} alt="Logo" style={{ width: 155, height: 155 }} />
      </Box>
      <List sx={{ width: '100%', mt: 0, ml: 8 }}>
        <ListItem 
          button 
          onClick={() => handleNavigation('/')}
          disableRipple
          sx={{ transition: 'none', mb: 1}}
        >
          <ListItemIcon sx={{ color: 'var(--text-color)' }}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" sx={{ color: 'var(--text-color)' }} />
        </ListItem>
        <ListItem 
          button 
          onClick={() => handleNavigation('/residents')}
          disableRipple
          sx={{ transition: 'none', mb: 2 }}
        >
          <ListItemIcon sx={{ color: 'var(--text-color)' }}>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Residents" sx={{ color: 'var(--text-color)' }} />
        </ListItem>
        <ListItem 
          button 
          onClick={() => handleNavigation('/invoices')}
          disableRipple
          sx={{ transition: 'none', mb: 2 }}
        >
          <ListItemIcon sx={{ color: 'var(--text-color)' }}>
            <ReceiptIcon />
          </ListItemIcon>
          <ListItemText primary="HOA Emails" sx={{ color: 'var(--text-color)' }} />
        </ListItem>
       <ListItem 
          button 
          onClick={() => handleNavigation('/payments')}
          disableRipple
          sx={{ transition: 'none', mb: 2 }}
        >
          <ListItemIcon sx={{ color: 'var(--text-color)' }}>
            <PaymentIcon />
          </ListItemIcon>
          <ListItemText primary="HOA Dues" sx={{ color: 'var(--text-color)' }} />
        </ListItem>
        <ListItem 
          button 
          onClick={() => handleNavigation('/bills-payable')}
          disableRipple
          sx={{ transition: 'none', mb: 2 }}
        >
          <ListItemIcon sx={{ color: 'var(--text-color)' }}>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText primary="Bills Payable" sx={{ color: 'var(--text-color)' }} />
        </ListItem>
        <ListItem 
          button 
          onClick={() => handleNavigation('/ledger')}
          disableRipple
          sx={{ transition: 'none', mb: 2 }}
        >
          <ListItemIcon sx={{ color: 'var(--text-color)' }}>
            <AccountBalanceIcon />
          </ListItemIcon>
          <ListItemText primary="Ledger" sx={{ color: 'var(--text-color)' }} />
        </ListItem>
        <ListItem 
          button 
          onClick={handleLogout}
          disableRipple
          sx={{ transition: 'none', mb: 2 }}
        >
          <ListItemIcon sx={{ color: 'var(--text-color)' }}>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" sx={{ color: 'var(--text-color)' }} />
        </ListItem>
      </List>
      <Box
        sx={{
          width: '100%',
          textAlign: 'center',
          padding: '16px 0',
          backgroundColor: 'var(--sidebar-bg-color)',
          color: 'var(--text-color)',
          marginTop: 'auto'
        }}
      >
        <Typography variant="caption">
          myHOA Private Release
          <br />
          Developed by <Link 
            href="https://garrett-flowers.com" 
            target="_blank" 
            underline="always"
            sx={{ color: '#99c6f4' }}
          >Garrett Flowers
          </Link>
        </Typography>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
