import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container, Typography, Box, Select, MenuItem, FormControl, InputLabel, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, InputAdornment, Checkbox } from '@mui/material';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { styled } from '@mui/system';

const CreditCell = styled(TableCell)(({ theme, entry }) => ({
  color: (entry.type === 'payment' || (entry.type === 'manual' && entry.subType === 'credit')) ? 'green' : 'inherit',
}));

const DebitCell = styled(TableCell)(({ theme, entry }) => ({
  color: (entry.type === 'bill' || (entry.type === 'manual' && entry.subType === 'debit')) ? 'red' : 'inherit',
}));

const Ledger = () => {
  const [bills, setBills] = useState([]);
  const [payments, setPayments] = useState([]);
  const [manualAdjustments, setManualAdjustments] = useState([]);
  const [residents, setResidents] = useState([]);
  const [balance, setBalance] = useState(0);
  const [sortOption, setSortOption] = useState('date');
  const [filterOption, setFilterOption] = useState('all');
  const [openExportDialog, setOpenExportDialog] = useState(false);
  const [openManualDialog, setOpenManualDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [exportOption, setExportOption] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [manualAmount, setManualAmount] = useState('');
  const [manualDescription, setManualDescription] = useState('');
  const [manualType, setManualType] = useState('');
  const [currentEntry, setCurrentEntry] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 30;

  useEffect(() => {
    const fetchData = async () => {
      const billsCollection = collection(db, 'bills');
      const billsSnapshot = await getDocs(billsCollection);
      const billsList = billsSnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(bill => bill.paid);

      const paymentsCollection = collection(db, 'payments');
      const paymentsSnapshot = await getDocs(paymentsCollection);
      const paymentsList = paymentsSnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(payment => payment.isPaid);

      const manualAdjustmentsCollection = collection(db, 'manualLedgerAdjustments');
      const manualAdjustmentsSnapshot = await getDocs(manualAdjustmentsCollection);
      const manualAdjustmentsList = manualAdjustmentsSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          date: data.date instanceof Date ? data.date : data.date.toDate()
        };
      });

      const residentsCollection = collection(db, 'residents');
      const residentsSnapshot = await getDocs(residentsCollection);
      const residentsList = residentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      setBills(billsList);
      setPayments(paymentsList);
      setManualAdjustments(manualAdjustmentsList);
      setResidents(residentsList);

      calculateBalance(billsList, paymentsList, manualAdjustmentsList);
    };

    fetchData();
  }, []);

  const calculateBalance = (billsList, paymentsList, manualAdjustmentsList) => {
    const totalPayments = paymentsList.reduce((acc, payment) => acc + parseFloat(payment.paymentAmount || 0), 0);
    const totalBills = billsList.reduce((acc, bill) => acc + parseFloat(bill.amount || 0), 0);
    const totalAdjustments = manualAdjustmentsList.reduce((acc, adjustment) => {
      if (adjustment.type === 'manual') {
        return adjustment.subType === 'credit' ? acc + parseFloat(adjustment.amount || 0) : acc - parseFloat(adjustment.amount || 0);
      }
      return acc;
    }, 0);
  
    setBalance(totalPayments - totalBills + totalAdjustments);
  };
  
  const getResidentName = (residentId) => {
    const resident = residents.find(res => res.id === residentId);
    return resident ? `${resident.firstName} ${resident.lastName}` : 'Unknown Resident';
  };

  const sortData = (data) => {
    switch (sortOption) {
      case 'date':
        return data.sort((a, b) => new Date(b.date) - new Date(a.date)); // Change here to sort by newest first
      case 'amount':
        return data.sort((a, b) => parseFloat(b.amount || b.paymentAmount) - parseFloat(a.amount || a.paymentAmount)); // Optionally sort by amount descending
      default:
        return data;
    }
  };

  const filterData = (data) => {
    switch (filterOption) {
      case 'bills':
        return data.filter(item => item.type === 'bill');
      case 'payments':
        return data.filter(item => item.type === 'payment');
      default:
        return data;
    }
  };

  const combinedData = [
    ...payments.map(payment => ({
      ...payment,
      date: payment.paidDate ? payment.paidDate.toDate() : new Date(),
      type: 'payment',
      amount: payment.paymentAmount,
      description: `HOA Dues Payment - ${getResidentName(payment.residentId)}`,
      reconciled: payment.reconciled || false // Add reconciled field
    })),
    ...bills.map(bill => ({
      ...bill,
      date: bill.datePaid ? bill.datePaid.toDate() : new Date(),
      type: 'bill',
      amount: bill.amount,
      description: `Payable to ${bill.payableTo} - Invoice # ${bill.invoiceNumber}`, // Added Invoice # to description
      reconciled: bill.reconciled || false // Add reconciled field
    })),
    ...manualAdjustments.map(adjustment => ({
      ...adjustment,
      date: adjustment.date ? adjustment.date instanceof Date ? adjustment.date : adjustment.date.toDate() : new Date(),
      description: adjustment.description,
      amount: adjustment.amount,
      type: 'manual',
      reconciled: adjustment.reconciled || false // Add reconciled field
    })),
  ];

  const sortedFilteredData = filterData(sortData(combinedData));

  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1);
  };

  const paginatedData = sortedFilteredData.slice(0, currentPage * entriesPerPage);

  const handleExport = () => {
    let filteredData = sortedFilteredData.filter(entry => {
      switch (exportOption) {
        case 'month':
          return entry.date >= new Date(new Date().setMonth(new Date().getMonth() - 1));
        case 'sixMonths':
          return entry.date >= new Date(new Date().setMonth(new Date().getMonth() - 6));
        case 'year':
          return entry.date >= new Date(new Date().setFullYear(new Date().getFullYear() - 1));
        case 'custom':
          return startDate && endDate ? (entry.date >= new Date(startDate) && entry.date <= new Date(endDate)) : true;
        default:
          return true;
      }
    });
  
    const doc = new jsPDF({ orientation: 'landscape' });
    const today = new Date();
    const formattedDate = today.toLocaleDateString(undefined, { day: 'numeric', month: 'long', year: 'numeric' });
  
    // Title and date
    doc.text('Ledger Report', 14, 16);
    doc.setFontSize(10);
    doc.text(`Date: ${formattedDate}`, 14, 22);
    doc.text(`Balance: $${balance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, 14, 28);
    doc.setFontSize(12);
  
    // Generate table with ledger entries
    doc.autoTable({
      head: [['Date', 'Description', 'Amount', 'Type', 'Reconciled']],
      body: filteredData.map(entry => [
        entry.date.toLocaleDateString(),
        entry.description,
        `$${parseFloat(entry.amount).toLocaleString()}`,
        entry.type === 'manual' ? (entry.subType === 'credit' ? 'Credit' : 'Debit') : (entry.type === 'payment' ? 'Credit' : 'Debit'),
        entry.reconciled ? 'Yes' : 'No'
      ]),
      theme: 'striped',
      startY: 34,  // Adjust start position to accommodate header information
      headStyles: { fillColor: [26, 29, 36] },
    });
    doc.save('ledger_report.pdf');
  };

  const handleExportDialogOpen = () => {
    setOpenExportDialog(true);
  };

  const handleExportDialogClose = () => {
    setOpenExportDialog(false);
    setExportOption('');
    setStartDate(null);
    setEndDate(null);
  };
  

  const handleManualDialogOpen = () => {
    setOpenManualDialog(true);
  };

  const handleManualDialogClose = () => {
    setOpenManualDialog(false);
    setManualAmount('');
    setManualDescription('');
    setManualType('');
  };

  const handleManualAddition = async () => {
    try {
      const adjustmentData = {
        description: manualDescription,
        amount: parseFloat(manualAmount),
        type: 'manual', // Static type for all manual adjustments
        subType: manualType, // This is 'credit' or 'debit', set via a select input in your dialog
        date: new Date(),
        reconciled: false // Default reconciled status
      };
  
      const manualAdjustmentsCollection = collection(db, 'manualLedgerAdjustments');
      const docRef = await addDoc(manualAdjustmentsCollection, adjustmentData);
      setManualAdjustments([...manualAdjustments, { ...adjustmentData, id: docRef.id }]);
  
      calculateBalance(bills, payments, [...manualAdjustments, { ...adjustmentData, id: docRef.id }]);
      handleManualDialogClose();
    } catch (error) {
      console.error("Error adding manual adjustment: ", error);
    }
  };
  
  const handleReconciledChange = async (entry, checked) => {
    try {
      const entryDoc = doc(db, entry.type === 'payment' ? 'payments' : entry.type === 'bill' ? 'bills' : 'manualLedgerAdjustments', entry.id);
      await updateDoc(entryDoc, { reconciled: checked });
      const updatedEntries = combinedData.map(e => e.id === entry.id ? { ...e, reconciled: checked } : e);
      const updatedBills = updatedEntries.filter(e => e.type === 'bill');
      const updatedPayments = updatedEntries.filter(e => e.type === 'payment');
      const updatedManualAdjustments = updatedEntries.filter(e => e.type === 'manual');
      setBills(updatedBills);
      setPayments(updatedPayments);
      setManualAdjustments(updatedManualAdjustments);
    } catch (error) {
      console.error("Error updating reconciled status: ", error);
    }
  };

  const handleDeleteManualAdjustment = async () => {
    try {
      const adjustmentDoc = doc(db, 'manualLedgerAdjustments', currentEntry.id);
      await deleteDoc(adjustmentDoc);
      const updatedAdjustments = manualAdjustments.filter(adj => adj.id !== currentEntry.id);
      setManualAdjustments(updatedAdjustments);
      calculateBalance(bills, payments, updatedAdjustments);
      handleDeleteDialogClose();
    } catch (error) {
      console.error("Error deleting manual adjustment: ", error);
    }
  };

  const handleDeleteDialogOpen = (entry) => {
    setCurrentEntry(entry);
    setOpenDeleteDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
    setCurrentEntry(null);
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom mt={4}>Ledger</Typography>
      <Box sx={{ marginBottom: 4 }}>
        <Typography variant="h6">Neighborhood Balance: ${balance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 4 }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <FormControl variant="outlined" sx={{ minWidth: 120 }}>
            <InputLabel>Sort By</InputLabel>
            <Select value={sortOption} onChange={(e) => setSortOption(e.target.value)} label="Sort By">
              <MenuItem value="date">Date</MenuItem>
              <MenuItem value="amount">Amount</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" sx={{ minWidth: 120 }}>
            <InputLabel>Filter By</InputLabel>
            <Select value={filterOption} onChange={(e) => setFilterOption(e.target.value)} label="Filter By">
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="bills">Bills</MenuItem>
              <MenuItem value="payments">HOA Dues Payments</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <Button variant="contained" color="primary" onClick={handleExportDialogOpen}>Export</Button>
          <Button variant="contained" color="secondary" sx={{ marginLeft: 2 }} onClick={handleManualDialogOpen}>Add Manual Entry</Button>
        </Box>
      </Box>
      <TableContainer component={Paper} sx={{ marginTop: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Reconciled</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((entry) => (
              <TableRow key={entry.id}>
                <TableCell>{entry.date.toLocaleDateString()}</TableCell>
                <TableCell>{entry.description}</TableCell>
                {entry.type === 'bill' || (entry.type === 'manual' && entry.subType === 'debit') ? (
                  <DebitCell entry={entry}>${parseFloat(entry.amount).toLocaleString()}</DebitCell>
                ) : (
                  <CreditCell entry={entry}>${parseFloat(entry.amount).toLocaleString()}</CreditCell>
                )}
                <TableCell>{entry.type === 'manual' ? entry.subType.charAt(0).toUpperCase() + entry.subType.slice(1) : entry.type.charAt(0).toUpperCase() + entry.type.slice(1)}</TableCell>
                <TableCell>
                  <Checkbox
                    checked={entry.reconciled}
                    onChange={(e) => handleReconciledChange(entry, e.target.checked)}
                  />
                </TableCell>
                <TableCell>
                  {entry.type === 'manual' && (
                    <Button color="secondary" onClick={() => handleDeleteDialogOpen(entry)}>
                      Delete
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {paginatedData.length < sortedFilteredData.length && (
        <Box display="flex" justifyContent="center" mt={2}>
          <Button variant="contained" onClick={handleLoadMore}>
            Load More
          </Button>
        </Box>
      )}
      <Dialog open={openExportDialog} onClose={handleExportDialogClose} maxWidth="xs" fullWidth>
        <DialogTitle>Export Options</DialogTitle>
        <DialogContent>
          <FormControl variant="outlined" sx={{ minWidth: 250, marginTop: 2, marginBottom: 2 }}>
            <InputLabel>Time Frame</InputLabel>
            <Select value={exportOption} onChange={(e) => setExportOption(e.target.value)} label="Time Frame">
              <MenuItem value="month">Past Month</MenuItem>
              <MenuItem value="sixMonths">Past 6 Months</MenuItem>
              <MenuItem value="year">Past Year</MenuItem>
              <MenuItem value="custom">Custom Date Range</MenuItem>
            </Select>
          </FormControl>
          {exportOption === 'custom' && (
            <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>
              <TextField
                label="Start Date"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
              <TextField
                label="End Date"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleExportDialogClose}>Cancel</Button>
          <Button onClick={() => { handleExport(); handleExportDialogClose(); }}>Export</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openManualDialog} onClose={handleManualDialogClose} maxWidth="xs" fullWidth>
        <DialogTitle>Add Manual Entry</DialogTitle>
        <DialogContent>
          <TextField
            label="Description"
            fullWidth
            margin="dense"
            value={manualDescription}
            onChange={(e) => setManualDescription(e.target.value)}
          />
          <TextField
            label="Amount"
            fullWidth
            margin="dense"
            value={manualAmount}
            onChange={(e) => setManualAmount(e.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
          <FormControl variant="outlined" fullWidth sx={{ marginTop: 2 }}>
            <InputLabel>Type</InputLabel>
            <Select value={manualType} onChange={(e) => setManualType(e.target.value)} label="Type">
              <MenuItem value="credit">Credit</MenuItem>
              <MenuItem value="debit">Debit</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleManualDialogClose}>Cancel</Button>
          <Button onClick={handleManualAddition}>Add</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDeleteDialog} onClose={handleDeleteDialogClose} maxWidth="xs" fullWidth>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this manual adjustment?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose}>Cancel</Button>
          <Button color="secondary" onClick={handleDeleteManualAdjustment}>Delete</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Ledger;
