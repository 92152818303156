import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getDoc, doc } from 'firebase/firestore';
import { db } from './firebaseConfig'; // Ensure you have the correct path

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);

    const logout = useCallback(async () => {
        const auth = getAuth();
        await signOut(auth);
        setUser(null);
        setIsAdmin(false);
    }, []);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setUser(user);
                const userDoc = await getDoc(doc(db, "users", user.uid));
                const isAdminRole = userDoc.exists() && userDoc.data().role === 'admin';
                setIsAdmin(isAdminRole);
                console.log("User Role: ", isAdminRole ? "admin" : "user"); // Debugging line
            } else {
                setUser(null);
                setIsAdmin(false);
            }
            setLoading(false); // Ensure loading is set to false once authentication state is determined
        });

        return unsubscribe;
    }, []);

    // Logout after 10 minutes of inactivity
    const resetTimeout = useCallback(() => {
        clearTimeout(window.logoutTimer);
        window.logoutTimer = setTimeout(logout, 10 * 60 * 1000); // 10 minutes
    }, [logout]);

    useEffect(() => {
        window.addEventListener('mousemove', resetTimeout);
        window.addEventListener('keypress', resetTimeout);
        resetTimeout(); // Initial timeout

        return () => {
            clearTimeout(window.logoutTimer);
            window.removeEventListener('mousemove', resetTimeout);
            window.removeEventListener('keypress', resetTimeout);
        };
    }, [resetTimeout]);

    return (
        <AuthContext.Provider value={{ user, loading, isAdmin, logout }}>
            {!loading && children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);