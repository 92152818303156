import React, { useState, useEffect } from 'react';
import { collection, getDocs, updateDoc, doc as firestoreDoc } from 'firebase/firestore';
import { pdf } from '@react-pdf/renderer';
import { db, storage } from '../firebaseConfig';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, CircularProgress, Checkbox, Dialog, InputAdornment, DialogActions, DialogContent, DialogTitle, TextField, Tooltip, Container } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled, { createGlobalStyle } from 'styled-components';
import InvoicePDF from './InvoicePDF';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';

const GlobalStyle = createGlobalStyle`
  .react-datepicker {
    background-color: #424242;
    color: white;
  }
  .react-datepicker__header {
    background-color: #424242;
    border-bottom: 1px solid #616161;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: white;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header {
    color: white;
  }
  .react-datepicker__navigation-icon::before {
    border-color: white;
  }
  .react-datepicker__day--selected {
    background-color: #757575;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: #616161;
  }
  .react-datepicker__day:hover {
    background-color: #616161;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    width: 100%;
  }
  .react-datepicker__close-icon::after {
    background-color: #424242;
    color: white;
  }
`;

const Invoices = () => {
  const [residents, setResidents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedForEmail, setSelectedForEmail] = useState({});
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [lateEmailDialogOpen, setLateEmailDialogOpen] = useState(false);
  const [customInvoiceOpen, setCustomInvoiceOpen] = useState(false);
  const [selectedResident, setSelectedResident] = useState(null);
  const [emailSending, setEmailSending] = useState(false);
  const [dueDate, setDueDate] = useState(new Date());
  const [customDueDate, setCustomDueDate] = useState(new Date());
  const [amount, setAmount] = useState(300);
  const [customAmount, setCustomAmount] = useState(300);
  const [progress, setProgress] = useState(0);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  useEffect(() => {
    const fetchResidents = async () => {
      const querySnapshot = await getDocs(collection(db, 'residents'));
      const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Sort the residents by lotNumber in ascending order
      const sortedData = data.sort((a, b) => Number(a.lotNumber) - Number(b.lotNumber));

      setResidents(sortedData);
      setSelectedForEmail(sortedData.reduce((acc, resident) => ({ ...acc, [resident.id]: false }), {}));
      setLoading(false);
    };

    const timeoutId = setTimeout(() => {
      fetchResidents();
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  const toggleEmailSelection = (id) => {
    setSelectedForEmail(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const toggleAllEmailSelections = () => {
    const allSelected = Object.values(selectedForEmail).every(Boolean);
    const newSelections = Object.keys(selectedForEmail).reduce((acc, id) => ({ ...acc, [id]: !allSelected }), {});
    setSelectedForEmail(newSelections);
  };

  const handleOpenEmailDialog = () => {
    setEmailDialogOpen(true);
  };

  const handleCloseEmailDialog = () => {
    setEmailDialogOpen(false);
  };

  const handleOpenLateEmailDialog = () => {
    setLateEmailDialogOpen(true);
  };

  const handleCloseLateEmailDialog = () => {
    setLateEmailDialogOpen(false);
  };

  const handleCloseSuccessDialog = () => {
    setSuccessDialogOpen(false);
  };

  const confirmEmailDues = async () => {
    setEmailSending(true);
    const emailsToSend = residents.filter(resident => selectedForEmail[resident.id]);

    setProgress(0);

    for (let i = 0; i < emailsToSend.length; i++) {
      const resident = emailsToSend[i];
      const doc = <InvoicePDF resident={resident} dueDate={formatDate(dueDate)} amount={amount} />;
      const blob = await pdf(doc).toBlob();
      const dateStr = formatDate(new Date());
      const filename = `Invoice_${resident.lastName}_${dateStr}.pdf`;
      const storageRef = ref(storage, `invoices/${filename}`);
      await uploadBytes(storageRef, blob);
      const url = await getDownloadURL(storageRef);

      await fetch('https://us-central1-hoa-management24.cloudfunctions.net/sendDueReminders', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: resident.email,
          pdfUrl: url,
          lastName: resident.lastName
        })
      });

      setProgress(i + 1);

      // Update emailSent status
      await updateDoc(firestoreDoc(db, 'residents', resident.id), { emailSent: true });
      setResidents(prevResidents =>
        prevResidents.map(r => r.id === resident.id ? { ...r, emailSent: true } : r)
      );
    }

    setEmailSending(false);
    setEmailDialogOpen(false);
    setSuccessDialogOpen(true);
  };

  const confirmLateEmailDues = async () => {
    setEmailSending(true);
    const emailsToSend = residents.filter(resident => selectedForEmail[resident.id]);

    setProgress(0);

    for (let i = 0; i < emailsToSend.length; i++) {
      const resident = emailsToSend[i];
      const doc = <InvoicePDF resident={resident} dueDate={formatDate(dueDate)} amount={amount} />;
      const blob = await pdf(doc).toBlob();
      const dateStr = formatDate(new Date());
      const filename = `Invoice_${resident.lastName}_${dateStr}.pdf`;
      const storageRef = ref(storage, `invoices/${filename}`);
      await uploadBytes(storageRef, blob);
      const url = await getDownloadURL(storageRef);

      await fetch('https://us-central1-hoa-management24.cloudfunctions.net/sendLatePaymentNotices', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: resident.email,
          pdfUrl: url,
          lastName: resident.lastName,
        })
      });

      setProgress(i + 1);

      // Update emailSent status
      await updateDoc(firestoreDoc(db, 'residents', resident.id), { emailSent: true });
      setResidents(prevResidents =>
        prevResidents.map(r => r.id === resident.id ? { ...r, emailSent: true } : r)
      );
    }

    setEmailSending(false);
    setLateEmailDialogOpen(false);
    setSuccessDialogOpen(true);
  };

  const openCustomInvoiceDialog = (resident) => {
    setSelectedResident(resident);
    setCustomDueDate(new Date());
    setCustomAmount(300);
    setCustomInvoiceOpen(true);
  };

  const formatDate = (date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [month, day, year].join('/');
  };

  const downloadCustomInvoice = async () => {
    const doc = <InvoicePDF resident={selectedResident} dueDate={formatDate(customDueDate)} amount={customAmount} />;
    const asBlob = await pdf(doc).toBlob();
    const dateStr = formatDate(new Date());
    const filename = `Invoice_${selectedResident.lastName}_${dateStr}.pdf`;
    const link = document.createElement('a');
    link.href = URL.createObjectURL(asBlob);
    link.download = filename;
    link.click();
    setCustomInvoiceOpen(false);
  };

  const handleResetAllEmailStatus = async () => {
    try {
      const updatedResidents = await Promise.all(
        residents.map(async (resident) => {
          const residentDoc = firestoreDoc(db, 'residents', resident.id);
          await updateDoc(residentDoc, { emailSent: false });
          return { ...resident, emailSent: false };
        })
      );
      setResidents(updatedResidents);
    } catch (error) {
      console.error("Error resetting email statuses: ", error);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>Loading Invoice Generator...</Typography>
      </Box>
    );
  }

  return (
    <Container sx={{ pr: 4, pt: 2 }}>
      <Typography variant="h4" component="h1" gutterBottom mt={2} mb={3}>HOA Email Creator</Typography>
      <GlobalStyle />
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Tooltip title="Send out the emails">
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleOpenEmailDialog} 
            startIcon={<SendIcon />}
            sx={{ mr: 2, mb: 2 }}
          >
            Email Dues
          </Button>
        </Tooltip>
        <Tooltip title="Send out late payment notices">
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleOpenLateEmailDialog} 
            startIcon={<SendIcon />}
            sx={{ mb: 2 }}
          >
            Email Late Payment Notices
          </Button>
        </Tooltip>
        <Tooltip title="Reset all email statuses">
          <Button 
            variant="outlined" 
            color="secondary" 
            onClick={handleResetAllEmailStatus}
            sx={{ mb: 2, ml: 2}}
          >
            Reset Email Status
          </Button>
        </Tooltip>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  onChange={toggleAllEmailSelections}
                  checked={Object.values(selectedForEmail).every(Boolean)}
                  indeterminate={Object.values(selectedForEmail).some(Boolean) && !Object.values(selectedForEmail).every(Boolean)}
                />
              </TableCell>
              <TableCell>Resident Name</TableCell>
              <TableCell>Associated Email</TableCell>
              <TableCell>Lot Number</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {residents.map((resident) => (
              <TableRow key={resident.id}>
                <TableCell padding="checkbox">
                  <Checkbox 
                    checked={selectedForEmail[resident.id]} 
                    onChange={() => toggleEmailSelection(resident.id)} 
                  />
                </TableCell>
                <TableCell style={{ color: resident.emailSent ? 'green' : 'inherit' }}>
                  {resident.firstName} {resident.lastName}
                </TableCell>
                <TableCell>{resident.email}</TableCell>
                <TableCell>{resident.lotNumber}</TableCell>
                <TableCell>
                  <Button color="primary" variant="outlined" onClick={() => openCustomInvoiceDialog(resident)}>
                    Custom
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={emailDialogOpen} onClose={handleCloseEmailDialog} fullWidth maxWidth="sm">
        <DialogTitle>Send to selected residents</DialogTitle>
        <DialogContent dividers>
          <StyledDatePicker
            selected={dueDate}
            onChange={(date) => setDueDate(date)}
            dateFormat="MM/dd/yyyy"
            customInput={<TextField fullWidth sx={{ mt: 2 }} label="Due Date" />}
            popperPlacement="bottom"
            withPortal
          />
          <TextField
            label="Amount"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            fullWidth
            sx={{ mt: 2 }}
          />
          {emailSending && (
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
              <CircularProgress size={24} sx={{ marginRight: 2 }} />
              <Typography variant="body1">
                Sending emails: {progress}/{residents.filter(resident => selectedForEmail[resident.id]).length}
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={confirmEmailDues} 
            disabled={emailSending}
            startIcon={<SendIcon />}
            sx={{ mr: 2 }}
          >
            {emailSending ? 'Sending...' : 'Send Batch Emails'}
          </Button>
          <Button onClick={handleCloseEmailDialog} startIcon={<CloseIcon />} disabled={emailSending}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={lateEmailDialogOpen} onClose={handleCloseLateEmailDialog} fullWidth maxWidth="sm">
        <DialogTitle>Send to selected residents</DialogTitle>
        <DialogContent dividers>
          <StyledDatePicker
            selected={dueDate}
            onChange={(date) => setDueDate(date)}
            dateFormat="MM/dd/yyyy"
            customInput={<TextField fullWidth sx={{ mt: 2 }} label="Due Date" />}
            popperPlacement="bottom"
            withPortal
          />
          <TextField
            label="Amount"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            fullWidth
            sx={{ mt: 2 }}
          />
          {emailSending && (
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
              <CircularProgress size={24} sx={{ marginRight: 2 }} />
              <Typography variant="body1">
                Sending emails: {progress}/{residents.filter(resident => selectedForEmail[resident.id]).length}
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={confirmLateEmailDues} 
            disabled={emailSending}
            startIcon={<SendIcon />}
            sx={{ mr: 2 }}
          >
            {emailSending ? 'Sending...' : 'Send Late Payment Notices'}
          </Button>
          <Button onClick={handleCloseLateEmailDialog} startIcon={<CloseIcon />} disabled={emailSending}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={successDialogOpen} onClose={handleCloseSuccessDialog}>
        <DialogTitle>Emails Sent</DialogTitle>
        <DialogContent>
          <Typography>All selected emails have been sent successfully.</Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={handleCloseSuccessDialog} startIcon={<CloseIcon />} sx={{ mr: 2 }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {customInvoiceOpen && (
        <Dialog open={customInvoiceOpen} onClose={() => setCustomInvoiceOpen(false)} fullWidth maxWidth="sm">
          <DialogTitle>Customize Invoice for {selectedResident?.firstName} {selectedResident?.lastName}</DialogTitle>
          <DialogContent dividers>
            <StyledDatePicker
              selected={customDueDate}
              onChange={(date) => setCustomDueDate(date)}
              dateFormat="MM/dd/yyyy"
              customInput={<TextField fullWidth sx={{ mt: 2 }} label="Due Date" />}
              popperPlacement="bottom"
              withPortal
            />
            <TextField
              label="Amount"
              type="number"
              value={customAmount}
              onChange={(e) => setCustomAmount(e.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              fullWidth
              sx={{ mt: 2 }}
            />
          </DialogContent>
          <DialogActions sx={{ p: 2 }}>
            <Button variant="contained" color="primary" onClick={downloadCustomInvoice} startIcon={<DownloadIcon />} sx={{ mr: 2 }}>
              Download Invoice
            </Button>
            <Button onClick={() => setCustomInvoiceOpen(false)} startIcon={<CloseIcon />}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Container>
  );
};

export default Invoices;
