import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import { Container, Typography, Box, List, ListItem, ListItemText, Divider, AppBar, Toolbar, IconButton, Menu, MenuItem, TextField, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { styled } from '@mui/system';

const CreditListItem = styled(ListItem)(({ theme }) => ({
    color: 'green',
}));

const DebitListItem = styled(ListItem)(({ theme }) => ({
    color: 'red',
}));

const MobileLedger = () => {
    const [bills, setBills] = useState([]);
    const [payments, setPayments] = useState([]);
    const [manualAdjustments, setManualAdjustments] = useState([]);
    const [residents, setResidents] = useState([]);
    const [balance, setBalance] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const entriesPerPage = 30;
    const navigate = useNavigate();
    const { logout } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            const billsCollection = collection(db, 'bills');
            const billsSnapshot = await getDocs(billsCollection);
            const billsList = billsSnapshot.docs
                .map(doc => ({ id: doc.id, ...doc.data() }))
                .filter(bill => bill.paid);

            const paymentsCollection = collection(db, 'payments');
            const paymentsSnapshot = await getDocs(paymentsCollection);
            const paymentsList = paymentsSnapshot.docs
                .map(doc => ({ id: doc.id, ...doc.data() }))
                .filter(payment => payment.isPaid);

            const manualAdjustmentsCollection = collection(db, 'manualLedgerAdjustments');
            const manualAdjustmentsSnapshot = await getDocs(manualAdjustmentsCollection);
            const manualAdjustmentsList = manualAdjustmentsSnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    id: doc.id,
                    ...data,
                    date: data.date instanceof Date ? data.date : data.date.toDate()
                };
            });

            const residentsCollection = collection(db, 'residents');
            const residentsSnapshot = await getDocs(residentsCollection);
            const residentsList = residentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            setBills(billsList);
            setPayments(paymentsList);
            setManualAdjustments(manualAdjustmentsList);
            setResidents(residentsList);

            calculateBalance(billsList, paymentsList, manualAdjustmentsList);
        };

        fetchData();
    }, []);

    const calculateBalance = (billsList, paymentsList, manualAdjustmentsList) => {
        const totalPayments = paymentsList.reduce((acc, payment) => acc + parseFloat(payment.paymentAmount || 0), 0);
        const totalBills = billsList.reduce((acc, bill) => acc + parseFloat(bill.amount || 0), 0);
        const totalAdjustments = manualAdjustmentsList.reduce((acc, adjustment) => {
            return adjustment.subType === 'credit' ? acc + parseFloat(adjustment.amount || 0) : acc - parseFloat(adjustment.amount || 0);
        }, 0);

        setBalance(totalPayments - totalBills + totalAdjustments);
    };

    const getResidentName = (residentId) => {
        const resident = residents.find(res => res.id === residentId);
        return resident ? `${resident.firstName} ${resident.lastName}` : 'Unknown Resident';
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        await logout();
        navigate('/login');
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const combinedData = [
        ...payments.map(payment => ({
            ...payment,
            date: payment.paidDate ? payment.paidDate.toDate() : new Date(),
            type: 'payment',
            amount: payment.paymentAmount,
            description: `HOA Dues Payment - ${getResidentName(payment.residentId)}`,
        })),
        ...bills.map(bill => ({
            ...bill,
            date: bill.datePaid ? bill.datePaid.toDate() : new Date(),
            type: 'bill',
            amount: bill.amount,
            description: `Payable to ${bill.payableTo} - Invoice # ${bill.invoiceNumber}`,
        })),
        ...manualAdjustments.map(adjustment => ({
            ...adjustment,
            date: adjustment.date ? adjustment.date instanceof Date ? adjustment.date : adjustment.date.toDate() : new Date(),
            description: adjustment.description,
            amount: adjustment.amount,
            type: 'manual',
        })),
    ];

    // Filter combined data based on search term
    const filteredData = combinedData.filter(entry => {
        return (
            entry.description.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    // Sort by date descending
    const sortedData = filteredData.sort((a, b) => new Date(b.date) - new Date(a.date));

    const paginatedData = sortedData.slice(0, currentPage * entriesPerPage);

    const handleLoadMore = () => {
        setCurrentPage(currentPage + 1);
    };

    return (
        <Container>
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuOpen}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        myHOA
                    </Typography>
                </Toolbar>
            </AppBar>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={() => navigate('/profiles')}>Directory</MenuItem>
                <MenuItem onClick={() => navigate('/MobilePayments')}>HOA Dues</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
            <Box mt={10}> {/* Adjust the top margin to prevent content from being hidden behind the AppBar */}
                <Typography variant="h4" component="h1" gutterBottom mt={4}>
                    Mobile Ledger
                </Typography>
                <Box mb={4}>
                    <Typography variant="h6">
                        Balance: ${balance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </Typography>
                </Box>
                <TextField
                    label="Search"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                <List>
                    {paginatedData.map((entry) => (
                        <React.Fragment key={entry.id}>
                            {entry.type === 'bill' || (entry.type === 'manual' && entry.subType === 'debit') ? (
                                <DebitListItem>
                                    <ListItemText
                                        primary={entry.description || 'No Description'}
                                        secondary={`Amount: $${parseFloat(entry.amount || 0).toLocaleString()} - Date: ${entry.date ? entry.date.toLocaleDateString() : 'No Date'}`}
                                    />
                                </DebitListItem>
                            ) : (
                                <CreditListItem>
                                    <ListItemText
                                        primary={entry.description || 'No Description'}
                                        secondary={`Amount: $${parseFloat(entry.amount || 0).toLocaleString()} - Date: ${entry.date ? entry.date.toLocaleDateString() : 'No Date'}`}
                                    />
                                </CreditListItem>
                            )}
                            <Divider />
                        </React.Fragment>
                    ))}
                </List>
                {paginatedData.length < sortedData.length && (
                    <Box display="flex" justifyContent="center" mt={2}>
                        <Button variant="contained" onClick={handleLoadMore}>
                            Load More
                        </Button>
                    </Box>
                )}
            </Box>
        </Container>
    );
};

export default MobileLedger;
